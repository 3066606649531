import { ChangeEvent, FC, useState } from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

// Components
import { Select } from 'components/inputs';
import useUI from 'hooks/useUI';
// Hooks
import { useI18n } from 'hooks/useI18n';

const IntlSelectView: FC = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { locale, locales, getRegion } = useI18n();
  const [currentLocale, setLocale] = useState(locale);
  const { closeMarketLanguage } = useUI();

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLocale(event.target.value);
  };

  const handleOnClick = () => {
    closeMarketLanguage();
    router.push(router.asPath, router.asPath, { locale: currentLocale });
  };

  return (
    <div className='height-100% bg'>
      <div className={'margin-y-md padding-x-component'}>
        <div className={'text-component margin-bottom-sm text-base'}>
          <h3>{formatMessage({ id: 'chooseCountry.title', defaultMessage: 'Choose your country' })}</h3>
          <p className={'text-sm'}>
            {formatMessage({
              id: 'chooseCountry.description',
              defaultMessage: `Currency, shipping destinations and freight costs follows your country selection.`,
            })}
          </p>
        </div>
        {locales && (
          <Select
            label={formatMessage({ id: 'chooseCountry.selectLabel', defaultMessage: 'Choose country' })}
            name='language'
            value={currentLocale}
            onChange={handleOnChange}
          >
            {locales.map((loc) => {
              return (
                <option key={loc} value={loc}>
                  {getRegion(loc)}
                </option>
              );
            })}
          </Select>
        )}
        <button className={'btn btn--primary btn--big btn--md width-100% margin-top-sm'} onClick={handleOnClick}>
          {formatMessage({
            id: 'chooseCountry.submitButton',
            defaultMessage: 'Continue',
            description: 'Button text for continuing with selection',
          })}
        </button>
      </div>
    </div>
  );
};

export default IntlSelectView;
