import { FC, memo, useState, useEffect } from 'react';
import Link from 'next/link';
import MegaMenu from '../MegaMenu';
import { AnimatePresence, motion } from 'framer-motion';

// Interfaces
import { CfReferenceLink } from 'interfaces/Contentful';

// Styles
import styles from './navigation.module.scss';

// Utils
import { getUrlForContentType } from 'utils/routes';
import useAuth from 'hooks/useAuth';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

interface Props {
  links?: CfReferenceLink[];
}

const MainNavigation: FC<Props> = ({ links }) => {
  const { isLoggedIn } = useAuth();
  const { formatMessage } = useIntl();

  const [selectedMainCategory, setSelectedMainCategory] = useState<CfReferenceLink | undefined>();

    const handleMegaNavToggle = (id: string) => () => {
      if (links) {
        setSelectedMainCategory(links.find(i => id === i.id));
      }
    };

    const router = useRouter();
      useEffect(() => {
        setSelectedMainCategory(undefined)
      }, [router.asPath])

  const menuItems = links?.map(link => {
    const slug = getUrlForContentType(link.slug, link.contentTypeId);

    return (
      <ul key={link.id}>
        <li 
          className='padding-y-md padding-x-xs' 
          onMouseEnter={handleMegaNavToggle(link.id)} 
          onMouseLeave={handleMegaNavToggle('')}
        >
          <Link href={slug} passHref>
            <a title={link.title} className={styles.link}>
              {link.title}
            </a>
          </Link>
          <AnimatePresence>
          {link.id === selectedMainCategory?.id &&
          <ul>
            <motion.li 
              initial='collapsed'
              animate='open'
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              exit='collapsed'
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <MegaMenu sub={link.subCategories}  handleSubMenuClick={() => setSelectedMainCategory(undefined)}/>
            </motion.li>
          </ul>
          }
          </AnimatePresence>
        </li>
      </ul>
    )
  });

  return (
    <nav role='navigation' className='height-100%'>
      <ul className={'flex flex-column flex-row@md justify-center@md height-100%'}>
        <span className='cursor-pointer flex'>
          {menuItems}
        </span>

        {isLoggedIn && (
          <li>
            <Link href='/my-products' passHref>
              <a
                title={formatMessage({ id: 'main-navigation.my-products.title', defaultMessage: 'My products' })}
                className={styles.link}
              >
                {formatMessage({ id: 'main-navigation.my-products.title', defaultMessage: 'My products' })}
              </a>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default memo(MainNavigation);
