import { FC } from 'react';
import { FormattedNumber } from 'react-intl';

import { useI18n } from 'hooks/useI18n';

interface Props {
  value: number;
}

const Currency: FC<Props> = ({ value }) => {
  const { currency } = useI18n();
  return <FormattedNumber value={value} style='currency' currency={currency} />;
};

export default Currency;
