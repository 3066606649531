import { FC, useState, useMemo, ReactChild } from 'react';
import cn from 'classnames';

import s from './interactive-table.module.scss';

import TableHead from './TableHead';
import TableRow from './TableRow';

interface Head {
  id: string;
  title: string;
  textAlignment?: 'left' | 'right' | 'center';
  sortable?: boolean;
}

interface Row {
  id: string;
  cells: RowCell[];
}

interface RowCell {
  id: string;
  truncate?: boolean;
  content: ReactChild | string;
}

interface SortingConfiration {
  key: string;
  direction: 'ascending' | 'descending';
}

interface Props {
  headers: Head[];
  rows: Row[];
  aria: {
    label: string;
  };
  stickyHeader?: boolean;
  className?: string;
}

const InteractiveTable: FC<Props> = ({ headers, rows, aria, stickyHeader = false, className }) => {
  const [sortConfig, setSortConfig] = useState<SortingConfiration | null>(null);

  const sortedRows = useMemo(() => {
    const sortableItems = rows;

    // If there is no sorting choosen then we return as is
    if (!sortConfig) return sortableItems;

    // Our custom sorting function
    sortableItems.sort((a, b) => {
      // Find a cell in each row that has the corresponding id to the sorting config key
      // e.g. the users clicks on the date and we need to find the cell which then has the id "date"
      const cellA = a.cells.find((cell) => cell.id === sortConfig.key);
      const cellB = b.cells.find((cell) => cell.id === sortConfig.key);

      // If either cellA or cellB exists then we do nothing
      if (!cellA || !cellB) return 0;

      // Sort depending on which is greater or lesser
      // note this only works on strings or numbers, not on for instance a react component
      if (cellA.content < cellB.content) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      } else if (cellA.content > cellB.content) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sortableItems;
  }, [rows, sortConfig]);

  const handleOnChangeSorting = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    // if we have a config and the current key is the same one as the one the user clicked then reverse the direction
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className={cn(s.root, className, { [s.sticky]: stickyHeader })}>
      <div className={s.inner}>
        <table className={s.table} aria-label={aria.label}>
          <thead className={s.header}>
            <tr className={s.row}>
              {headers.map((header) => (
                <TableHead
                  key={header.id}
                  id={header.id}
                  title={header.title}
                  sortable={header.sortable}
                  onClick={handleOnChangeSorting}
                />
              ))}
            </tr>
          </thead>
          <tbody className={s.body}>
            {sortedRows.map((row) => (
              <TableRow key={row.id} cells={row.cells} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InteractiveTable;
