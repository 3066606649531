import { ChangeEvent, FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Document } from '@contentful/rich-text-types';

// Components
import { Featurebar, RichTextContent } from 'components/common';
import { Checkbox } from 'components/inputs';

// Hooks
import useUI from 'hooks/useUI';

interface Props {
  content: Document;
}

const CookieNotice: FC<Props> = ({ content }) => {
  const [settings, setSettings] = useState({
    essentials: true,
    analytics: false,
    advertising: false,
    personalisation: false,
  });
  const { cookieNoticeOpen, closeCookieNotice, changeCookieSettings } = useUI();
  const { formatMessage } = useIntl();

  const handleOnAccept = () => {
    changeCookieSettings(settings);
    closeCookieNotice();
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.checked,
    });
  };

  if (!content) {
    return null;
  }

  return (
    <Featurebar
      hide={!cookieNoticeOpen}
      action={
        <button className='btn btn--primary width-100%' onClick={handleOnAccept}>
          <FormattedMessage id='layout.cookiePolicyButton' defaultMessage='Accept cookies' />
        </button>
      }
    >
      <RichTextContent content={content} />
      <div>
        <Checkbox
          name={'essentials'}
          label={formatMessage({ id: 'cookie-notice.essentials', defaultMessage: 'Essentials' })}
          checked={settings.essentials}
          onChange={handleOnChange}
          disabled
        />
        <Checkbox
          name={'analytics'}
          label={formatMessage({ id: 'cookie-notice.analytics', defaultMessage: 'Analytics' })}
          checked={settings.analytics}
          onChange={handleOnChange}
        />
        <Checkbox
          name={'advertising'}
          label={formatMessage({ id: 'cookie-notice.advertising', defaultMessage: 'Advertising' })}
          checked={settings.advertising}
          onChange={handleOnChange}
        />
        <Checkbox
          name={'personalisation'}
          label={formatMessage({ id: 'cookie-notice.personalisation', defaultMessage: 'Personalisation' })}
          checked={settings.personalisation}
          onChange={handleOnChange}
        />
      </div>
    </Featurebar>
  );
};

export default CookieNotice;
