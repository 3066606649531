import axios, { AxiosRequestConfig } from 'axios';
import https from 'https';

export const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STORM_API_URL,
  withCredentials: true,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }), // Required if running towards non approved HTTPS certificates
});

const beforeRequestIsSent = (config: AxiosRequestConfig) => {
  /**
   * Attach any user token to the authorization header
   */
  if (typeof window !== 'undefined') {
    const user = window.localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      config.headers['Authorization'] = 'Bearer ' + parsedUser.token;
    }
  }

  return config;
};

axiosClient.interceptors.request.use(beforeRequestIsSent);
