import React, { createElement, FC, Fragment, useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';
import { useMemo } from 'react';

// Algolia plugins
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';

// Algolia
import { SearchClient } from 'algoliasearch/lite';
import { BaseItem } from '@algolia/autocomplete-core';
import { autocomplete, AutocompleteOptions, Render } from '@algolia/autocomplete-js';
import { usePagination, useSearchBox } from 'react-instantsearch-hooks';

// Config
import { algoliaQuerySuggestionsIndex } from 'config/algolia/algoliaConfig';

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  className?: string;
  suggestionsSearchClient: SearchClient;
  searchAsYouType: boolean;
  onNewSearch?: () => void; //Callback function to notify when a new search is made
};

type SetInstantSearchUiStateOptions = {
  query: string;
};

export const AlgoliaAutocomplete: FC<AutocompleteProps> = ({
  suggestionsSearchClient,
  className,
  searchAsYouType,
  onNewSearch,
  ...autocompleteProps
}) => {
  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const { query, refine: setQuery } = useSearchBox();
  const { refine: setPage } = usePagination();
  const [instantSearchUiState, setInstantSearchUiState] = useState<SetInstantSearchUiStateOptions>({ query });
  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: 'instantsearch',
      limit: 6,
      transformSource({ source }) {
        return {
          ...source,
          onSelect(props) {
            setInstantSearchUiState({ query: props.item.label });
          },
        };
      },
    });

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient: suggestionsSearchClient,
      indexName: algoliaQuerySuggestionsIndex,
      getSearchParams() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return recentSearches.data!.getAlgoliaSearchParams({
          hitsPerPage: 6,
        });
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect({ item }) {
            setInstantSearchUiState({ query: item.query });
          },
          getItems(params) {
            if (!params.state.query) {
              return [];
            }
            return source.getItems(params);
          },
        };
      },
    });

    return [recentSearches, querySuggestions];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setQuery(instantSearchUiState.query);
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instantSearchUiState]);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      initialState: { query },
      plugins: plugins,
      onReset() {
        setInstantSearchUiState({ query: '' });
      },
      onSubmit({ state }) {
        setInstantSearchUiState({ query: state.query });
        if (onNewSearch) {
          onNewSearch();
        }
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query && searchAsYouType) {
          setInstantSearchUiState({
            query: state.query,
          });
        }
      },
      renderer: { createElement, Fragment, render: render as Render },
    });

    return () => autocompleteInstance.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={className} ref={autocompleteContainer} />;
};

export default AlgoliaAutocomplete;
