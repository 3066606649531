import { FC } from 'react';
import cn from 'classnames';

import s from './blockquote.module.scss';

interface Props {
  cite?: string;
  from?: string;
  className?: string;
}

const BlockQuote: FC<Props> = ({ cite, from, className, children }) => {
  return (
    <blockquote cite={cite} className={cn('text-center', className)}>
      <div className={cn(s.quote, 'text-component')}>
        <p className='text-md text-xl@md'>{children}</p>
      </div>
      {from && <footer className='margin-top-md text-sm color-contrast-medium'>— {from}</footer>}
    </blockquote>
  );
};

export default BlockQuote;
