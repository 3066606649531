import { FC, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import nProgress from 'nprogress';
import { ErrorProps } from 'next/error';

// Styles
import '../styles/index.scss';
import '../styles/nprogress.css';

// Contexts
import { UiProvider } from 'contexts/ui';
import { BasketProvider } from 'contexts/basket';
import { AuthProvider } from 'contexts/auth';

// Algolia
import '@algolia/autocomplete-theme-classic'; 

// Components
import { Head } from 'components/common';
import Scripts from '../components/common/Scripts';

const Noop: FC = ({ children }) => {
  return <>{children}</>;
};

interface CustomApp extends AppProps {
  err: ErrorProps;
}

const CustomApp: FC<CustomApp> = ({ Component, pageProps, err }) => {
  const router = useRouter();
  const [messages, setMessages] = useState<any>({});

  useEffect(() => {
    async function loadMessage(locale?: string) {
      try {
        switch (locale) {
          case 'en':
          case 'en-US':
          case 'en-GB':
            return await import(`../compiled-lang/en.json`);
          case 'sv':
          case 'sv-SE':
            return await import(`../compiled-lang/sv.json`);
          default:
            return await import(`../compiled-lang/en.json`);
        }
      } catch (error) {
        const fallback = await import(`../compiled-lang/en.json`);
        return fallback;
      }
    }

    loadMessage(router.locale).then((messages) => setMessages(messages.default));
  }, [router.locale]);

  const handleOnIntlError = (error: any) => {
    // Don't log missing translation error
    if (process.env.NODE_ENV !== 'production' && error.code !== 'MISSING_TRANSLATION') {
      console.error(error);
    }
  };

  // If the component has a Layout prop e.g. "MyPage.Layout = MyLayoutComponent" than use that otherwise
  // use the Noop layout components
  const Layout = (Component as any).Layout || Noop;

  useEffect(() => {
    const loaderStart = () => {
      nProgress.start();
    };

    const loaderStop = () => {
      nProgress.done();
    };

    router.events.on('routeChangeStart', loaderStart);
    router.events.on('routeChangeComplete', loaderStop);
    router.events.on('routeChangeError', loaderStop);

    return () => {
      router.events.off('routeChangeStart', loaderStart);
      router.events.off('routeChangeComplete', loaderStop);
      router.events.off('routeChangeError', loaderStop);
    };
  }, [router]);

  return (
    <>
      <Head />
      <Scripts />
      <AuthProvider>
        <UiProvider>
          <IntlProvider
            locale={router.locale || router.defaultLocale || 'sv-SE'}
            defaultLocale={router.defaultLocale}
            messages={messages}
            onError={handleOnIntlError}
          >
            <BasketProvider>
              {/* <MaintenancePage /> */}
              <Layout pageProps={pageProps}>
                <Component {...pageProps} err={err} />
              </Layout>
            </BasketProvider>
          </IntlProvider>
        </UiProvider>
      </AuthProvider>
    </>
  );
};

export default CustomApp;
