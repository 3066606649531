import { FC } from 'react';
import Link from 'next/link';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import Image from 'next/image';

// Interfaces
import { AlgoliaProductModel } from 'interfaces/Algolia';

// Styles
import styles from './algolia-product-card.module.scss';

// Hooks
import { useI18n } from 'hooks/useI18n';
import useBasket from 'hooks/useBasket';
import useAuth from 'hooks/useAuth';

// Types
import { Fragment } from 'react';

// Components
import { SkeletonText } from 'components/ui';

// Algolia
import { Highlight } from 'react-instantsearch-hooks-web';
import { Hit } from 'instantsearch.js/es/types/results';

interface Props {
  product: Hit<AlgoliaProductModel>;
  pricesAreLoading?: boolean;
}

const AlgoliaProductCard: FC<Props> = ({ product, pricesAreLoading = false }) => {
  const { currency } = useI18n();
  const { addProduct } = useBasket();
  const { isLoggedIn } = useAuth();
  const handleOnAddToCart = () => {
    addProduct(product.partNo, 1);
  };

  const action = isLoggedIn ? (
    <Fragment>
      {pricesAreLoading ? (
        <SkeletonText style={{ height: '19px', borderRadius: '5px' }} />
      ) : (
        <>
          {' '}
          <span className={styles.price}>
            <FormattedNumber value={product.price} style={'currency'} currency={currency} />
          </span>
        </>
      )}
      <p className={styles.vat}>
        <FormattedMessage id='product-card.excludeVat' defaultMessage='Exkl. moms' />
      </p>
      <div className='margin-top-sm'>
        <button className='btn btn--primary width-100%' onClick={handleOnAddToCart}>
          <FormattedMessage id='product-view.addToCartButton' defaultMessage='Add to cart' />
        </button>
      </div>
    </Fragment>
  ) : (
    <div className='text-component text-sm font-italic text-center'>
      <p>
        <FormattedMessage id='product-view.require-login' defaultMessage='You must login to see the price' />
      </p>
    </div>
  );

  return (
    <div className={styles.card}>
      <Link href={`/product/${product.uniqueName}`} passHref>
        <a className={styles.imageLink} title={product.name}>
          {product.imageUrl ? (
            <Image
              placeholder='blur'
              blurDataURL={`${product.imageUrl}?w$80&h=80&q=40`}
              src={product.imageUrl}
              alt={product.name || 'Product Image'}
              width={800}
              height={800}
              quality='85'
              objectFit='contain'
            />
          ) : (
            <Image src='/defaultW.png' alt='default-image' width={800} height={800} quality='85' objectFit='contain' />
          )}
        </a>
      </Link>

      <div className={styles.content}>
        <div className='flex-grow'>
          <p className='color-secondary text-sm margin-bottom-xs'>{product.manufacturer?.name}</p>
          <Link href={`/product/${product.uniqueName}`} passHref>
            <a className={styles.title} title={product.name}>
              <Highlight hit={product} attribute={'name'} />
            </a>
          </Link>

          <div
            className={`${styles.excerpt} line-height-lg`}
            dangerouslySetInnerHTML={{ __html: product.description || '' }}
          />
        </div>
        {action}
      </div>
    </div>
  );
};

export default AlgoliaProductCard;
