import { FC, memo } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Avatar } from 'components/ui';

import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';

const LoginLink: FC = () => {
  const { openAuthModal } = useUI();
  const { isLoggedIn } = useAuth();
  const { formatMessage } = useIntl();

  const handleOnOpenModal = () => {
    openAuthModal();
  };

  if (isLoggedIn) {
    return (
      <Link href='/account' passHref>
        <a className='flex items-center margin-left-md padding-y-md padding-y-0@md'>
          <div className='margin-right-sm' style={{ transform: 'translateY(-1px)' }}>
            <Avatar className='margin-right-xxxs' />
          </div>
          <span>
            <FormattedMessage id='header.account-title' defaultMessage='My account' />
          </span>
        </a>
      </Link>
    );
  }

  return (
    <button
      className={cn('reset', 'block', 'cursor-pointer', 'padding-y-md padding-y-0@md')}
      aria-label={formatMessage({
        id: 'header.openLoginOrSignUp',
        defaultMessage: 'Open to login or sign-up',
        description: 'Button for opening login or sign up modal',
      })}
      onClick={handleOnOpenModal}
    >
      <div className='flex items-center margin-left-md'>
        <div style={{ transform: 'translateY(-1px)' }}>
          <Avatar className='margin-right-xs' />
        </div>
        <span>
          <FormattedMessage id='header.account-title' defaultMessage='My account' />
        </span>
      </div>
    </button>
  );
};

export default memo(LoginLink);
