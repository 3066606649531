import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

// Styling
import styles from './header.module.scss';

// Components
import { MainNavigation, Logotype } from 'components/common';
import { ShoppingBag } from 'components/icons';
import HamburgerButton from './../Hamburger/Index';
import { SearchIcon } from 'components/icons';

// Hooks
import useUI from 'hooks/useUI';
import useBasket from 'hooks/useBasket';

// Interfaces
import { CfReferenceLink } from 'interfaces/Contentful';
import useAuth from 'hooks/useAuth';
import { useRouter } from 'next/router';

interface Props {
  menu?: CfReferenceLink[];
}
const Header: FC<Props> = ({ menu }) => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { openBasket, openMobileNav, mobileNavOpen, toggleSearch } = useUI();
  const { getTotalQuantity } = useBasket();

  const handleOnShoppingBagClick = () => {
    openBasket();
  };

  const handleOnHamburgerClick = () => {
    // Toggle open/closed state
    openMobileNav();
  };

  const handleOnSearchClick = () => {
    // Toggle open/closed state
    toggleSearch();
  };

  const classes = cn(styles.header, 'position-relative');

  const basketQuantity = getTotalQuantity();

  return (
    <header className={classes}>
      <div className={cn('container', 'max-width-lg', styles.container)}>
        <HamburgerButton isOpen={mobileNavOpen} onClick={handleOnHamburgerClick} />
        <div className={styles.logoWrapper}>
          <div className={styles.logo}>
            <Logotype />
          </div>
        </div>
        <div className={styles.desktop}>
          <MainNavigation links={menu} />
        </div>
        <ul
          className={cn(
            styles.actions,
            'flex',
            'gap-x-sm',
            'gap-x-md@lg',
            'flex-grow',
            'flex-basis-0',
            'items-center',
            'justify-end'
          )}
        >
          {router.pathname !== '/search' && (
            <li>
              <button className={cn('reset', styles.action)} onClick={handleOnSearchClick}>
                <SearchIcon />
              </button>
            </li>
          )}

          {isLoggedIn && (
            <li className={styles.actionItem}>
              <button
                className={cn('reset', styles.action)}
                aria-label={formatMessage({ id: 'header.openBasket', defaultMessage: 'Open Shopping Bag' })}
                onClick={handleOnShoppingBagClick}
              >
                <ShoppingBag className={'icon--sm'} />
                <span className={styles.bagCount}>{basketQuantity}</span>
              </button>
            </li>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Header;
