import { FC, memo } from 'react';
import Link from 'next/link';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { Fragment } from 'react';

// Styles
import styles from './product-card.module.scss';

// Hooks
import { useI18n } from 'hooks/useI18n';
import useBasket from 'hooks/useBasket';
import useAuth from 'hooks/useAuth';

// Types
import { ProductListItem } from 'interfaces/Storm/Product';

// Components
import { SkeletonText } from 'components/ui';

interface Props {
  product: ProductListItem;
  pricesAreLoading?: boolean;
  aspectRatio?: '1:1' | '2:3';
}

const ProductCardComponent: FC<Props> = ({ product, pricesAreLoading, aspectRatio = '2:3' }) => {
  const { currency } = useI18n();
  const { addProduct } = useBasket();
  const { isLoggedIn } = useAuth();
  const handleOnAddToCart = () => {
    addProduct(product.partNo, 1);
  };
  const imageWidth = aspectRatio === '1:1' ? '800' : '540';
  const imageHeight = aspectRatio === '1:1' ? '800' : '380';

  const action = isLoggedIn ? (
    <Fragment>
      {pricesAreLoading ? (
        <SkeletonText style={{ height: '19px', borderRadius: '5px' }} />
      ) : (
        <>
          {' '}
          <span className={styles.price}>
            <FormattedNumber value={product.price} style={'currency'} currency={currency} />
          </span>
          <p className={styles.vat}>
            <FormattedMessage id='product-card.excludeVat' defaultMessage='Exkl. moms' />
          </p>
        </>
      )}
      <div className='margin-top-sm'>
        <button className='btn btn--primary width-100%' onClick={handleOnAddToCart}>
          <FormattedMessage id='product-view.addToCartButton' defaultMessage='Add to cart' />
        </button>
      </div>
    </Fragment>
  ) : (
    <div className='text-component text-sm font-italic text-center'>
      <p>
        <FormattedMessage id='product-view.require-login' defaultMessage='You must login to see the price' />
      </p>
    </div>
  );

  // const url = new URL(product?.primaryImageUrl || '');

  return (
    <div className={styles.card}>
      <Link href={`/product/${product.uniqueName}`} passHref>
        <a className={styles.imageLink} title={product.name}>
          {product.primaryImageUrl ? (
            <Image
              src={product.primaryImageUrl}
              placeholder='blur'
              blurDataURL={`${product.primaryImageUrl}?w$&h=20&q=30`}
              alt={product.name || 'Product Image'}
              width={imageWidth}
              height={imageHeight}
              quality='85'
              objectFit='contain'
            />
          ) : (
            <Image
              src='/defaultW.png'
              alt='default-image'
              width={imageWidth}
              height={imageHeight}
              quality='85'
              objectFit='contain'
            />
          )}
        </a>
      </Link>

      <div className={styles.content}>
        <div className='flex-grow'>
          <p className='color-secondary text-sm margin-bottom-xs'>{product.manufacturer?.name}</p>
          <Link href={`/product/${product.uniqueName}`} passHref>
            <a className={styles.title} title={product.name}>
              {product.name}
            </a>
          </Link>

          <div
            className={`${styles.excerpt} line-height-lg`}
            dangerouslySetInnerHTML={{ __html: product.description || '' }}
          />
        </div>
        {action}
      </div>
    </div>
  );
};

const ProductCard = ProductCardComponent;
ProductCard.displayName = 'ProductCard';

export default ProductCard;
