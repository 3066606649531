import { FC, useState, useEffect, useCallback, ReactNode } from 'react';
import { useRouter } from 'next/router';
import qs from 'query-string';
import set from 'lodash/fp/set';
import isEqual from 'lodash/fp/isEqual';
import debounce from 'lodash/fp/debounce';
import { useIntl } from 'react-intl';

// Components
import { Accordion, AccordionItem } from 'components/common';
import { CheckboxList, MultiValueRangeSlider } from 'components/inputs';

// Styles
import s from './product-filter.module.scss';

// Hooks
import { usePrevious } from 'hooks/usePrevious';

// Utils
import { removeFromLast } from 'utils/routes';

// Interfaces
import { FilterItem } from 'interfaces/Storm/Filter';

interface FilterState {
  mfrf?: string[];
  catf?: string[];
  priceMin?: number | string[];
  priceMax?: number | string[];
}

interface Props {
  filters: FilterItem[];
  defaultOpen: boolean;
}

const defaultFilterState = {};

const getPriceSubtitle = ({ priceMin, priceMax }: FilterState) => {
  if (priceMin && priceMax) {
    return `${priceMin} - ${priceMax}`;
  }
  return '';
};

const getPriceParam = ({ priceMin, priceMax }: FilterState) => {
  const values: { [key: string]: null | number } = {
    min: null,
    max: null,
  };

  if (priceMin) {
    const currentMin = Array.isArray(priceMin) ? priceMin.toString() : priceMin;
    const minValue = typeof currentMin === 'number' ? currentMin : parseInt(currentMin);
    values.min = minValue;
  }

  if (priceMax) {
    const currentMax = Array.isArray(priceMax) ? priceMax.toString() : priceMax;
    const maxValue = typeof currentMax === 'number' ? currentMax : parseInt(currentMax);
    values.max = maxValue;
  }

  return values;
};

const ProductFilter: FC<Props> = ({ filters, defaultOpen }) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const previousRoute = usePrevious(router.asPath);
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);

  // Update filterState when the component mounts
  useEffect(() => {
    // Get search params from the router.query
    const searchParams = Object.entries(router.query)
      .filter(([key]) => key !== 'slug')
      .reduce((accum, [key, values]) => {
        if (!values) {
          return accum;
        }

        const setValues = Array.isArray(values) ? values : values.split(',');
        return set(key, setValues, accum);
      }, {});

    // Create a string with all searchParams we got
    const searchString = qs.stringify(searchParams, { skipNull: true, arrayFormat: 'comma' });

    // Create the slug
    const slug = Array.isArray(router.query.slug) ? router.query.slug.join('/') : router.query.slug;

    // Combine the new slug & searchParams into a new asPath
    const asPath = `/category/${slug}${searchString.length > 0 ? '?' + searchString : ''}`;

    // If the new asPath is not equal to the current asPath then navigate to the new asPath
    if (asPath !== router.asPath) {
      router.push({ pathname: router.pathname, query: searchString }, asPath, { shallow: true });
    }
  }, [filterState, router]);

  // If we have navigated to a new path then reset the filter state
  useEffect(() => {
    const currentPath = removeFromLast(router.asPath, '?');
    const previousPath = previousRoute && removeFromLast(previousRoute, '?');
    if (previousPath && currentPath !== previousPath) {
      setFilterState(defaultFilterState);
    }
  }, [router.asPath, previousRoute]);

  // Call handleOnUpdateQuery after 500 ms since the last call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateQuery = useCallback(
    debounce(500, (newState) => handleOnUpdateQuery(newState)),
    [router.asPath, router.pathname]
  );

  // Navigate to the new route if we have a new filterState
  const handleOnUpdateQuery = (newFilterState: FilterState) => {
    const searchParams = Object.entries(newFilterState).reduce((accum, [key, values]) => {
      const setValues = Array.isArray(values) && values.length > 0 ? values.join(',') : values;
      return set(key, setValues, accum);
    }, {});
    const searchString = qs.stringify(searchParams, { skipNull: true, arrayFormat: 'comma' });
    const slug = Array.isArray(router.query.slug) ? router.query.slug.join('/') : router.query.slug;
    const asPath = `/category/${slug}${searchString.length > 0 ? '?' + searchString : ''}`;
    if (asPath !== router.asPath) {
      router.push({ pathname: router.pathname, query: searchString }, asPath);
    }
  };

  const handleOnFilterChange = (id: string, activeFilters: string[]) => {
    const updatedFilter = set([id], activeFilters, filterState);
    setFilterState(updatedFilter);
    handleOnUpdateQuery(updatedFilter);
  };

  const handleOnPriceChange = ({ min, max }: { min: number; max: number }) => {
    const withMin = set('priceMin', min, filterState);
    const withMax = set('priceMax', max, withMin);
    setFilterState(withMax);
    debounceUpdateQuery(withMax);
  };

  const getSubtitle = (itemCount: number): string | ReactNode => {
    return formatMessage(
      {
        id: 'product-filter.subtitle',
        defaultMessage: `{itemCount, plural, =0 {All} one {# active} other {# active}}`,
      },
      {
        itemCount: itemCount || 0,
      }
    );
  };

  return (
    <div className={s.productFilter}>
      <Accordion>
        {filters.map((filter) => {
          if (!filter.items || filter.items.length === 0) {
            return null;
          }
          
          switch (filter.name) {
            case 'mfrf': {
              return (
                <AccordionItem
                  key={filter.type}
                  title={formatMessage({
                    id: 'product-filter.manufacturer',
                    defaultMessage: 'Brands',
                    description: 'Name for manufacturer filter',
                  })}
                  subTitle={getSubtitle(filterState.mfrf?.length || 0)}
                  defaultOpen={Array.isArray(filterState.mfrf) && filterState.mfrf.length > 0 ? true : false}
                >
                  <CheckboxList
                    id={filter.name}
                    items={filter.items?.map((item) => ({
                      id: item.value,
                      title: item.name,
                      suffix: `(${item.count})`,
                    }))}
                    active={filterState.mfrf}
                    onChange={handleOnFilterChange}
                  />
                </AccordionItem>
              );
            }
            case 'catf': {
              return (
                <AccordionItem
                  key={filter.type}
                  title={formatMessage({
                    id: 'product-filter.categories',
                    defaultMessage: 'Categories',
                    description: 'Name for categories filter',
                  })}
                  subTitle={getSubtitle(filterState.catf?.length || 0)}
                  defaultOpen={Array.isArray(filterState.catf) && filterState.catf.length > 0 ? true : false}
                >
                  <CheckboxList
                    id={filter.name}
                    items={filter.items?.map((item) => ({
                      id: item.value,
                      title: item.name,
                      suffix: `(${item.count})`,
                    }))}
                    active={filterState.catf}
                    onChange={handleOnFilterChange}
                  />
                </AccordionItem>
              );
            }
            case 'prcf': {
              const { min, max } = getPriceParam(filterState);
              const subtitle = getPriceSubtitle(filterState);
              return (
                <AccordionItem
                  key={filter.type}
                  title={formatMessage({
                    id: 'product-filter.price',
                    defaultMessage: 'Price',
                    description: 'Name for price filter',
                  })}
                  subTitle={subtitle}
                  defaultOpen={defaultOpen}
                >
                  <div>
                    {filter.items.map((item) => {
                      const value = {
                        min: min !== null ? Math.round(min) : Math.round(item.from),
                        max: max !== null ? Math.round(max) : Math.round(item.to),
                      };
                      return (
                        <MultiValueRangeSlider
                          key={item.id || 'price'}
                          value={value}
                          min={Number(item.from.toFixed())}
                          max={Number(item.to.toFixed())}
                          step={100}
                          ariaLabels={[
                            formatMessage({ id: 'product-filter.price.minLabel', defaultMessage: 'Min' }),
                            formatMessage({ id: 'product-filter.price.maxLabel', defaultMessage: 'Max' }),
                          ]}
                          onChange={handleOnPriceChange}
                        />
                      );
                    })}
                  </div>
                </AccordionItem>
              );
            }
            default:
              return null;
          }
        })}
      </Accordion>
    </div>
  );
};

export default ProductFilter;
