import { FC } from 'react';

const SearchIcon: FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='22'>
      <g stroke='#1A1A1A' strokeWidth='1.4' fill='none' fillRule='evenodd'>
        <ellipse cx='10.2' cy='10.389' rx='9.5' ry='9.689' />
        <path strokeLinecap='round' d='M18.429 17.519L24 22' />
      </g>
    </svg>
  );
};

export default SearchIcon;
