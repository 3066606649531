import { FC } from 'react';

// Styles
import cn from 'classnames';

interface Props {
  size: 'sm' | 'lg';
}

const CircleLoader: FC<Props> = ({ size }) => {
  const classes = cn('circle-loader', 'circle-loader--v1', {
    'circle-loader--sm': size === 'sm',
  });
  return (
    <div className={classes} role='alert'>
      <p className='circle-loader__label'>Content is loading...</p>
      <div aria-hidden='true'>
        <div className='circle-loader__circle'></div>
      </div>
    </div>
  );
};

export default CircleLoader;
