import { DataLayer } from 'interfaces/gtm';
import { ProductDetail } from 'interfaces/Storm/ProductDetail';
import { BasketItem } from 'interfaces/Storm/Basket';
import { CheckoutBasketItem } from 'interfaces/Storm/Checkout';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_STORM_API_URL;

declare global {
  interface Window {
    dataLayer: DataLayer[];
  }
}

const pushToDataLayer = (obj: DataLayer): void => {
  if (typeof window.dataLayer === 'undefined' && !Array.isArray(window.dataLayer)) {
    window.dataLayer = [];
  }
  window.dataLayer.push(obj);
};

// @docs https://developers.google.com/tag-manager/ecommerce-ga4#measure_viewsimpressions_of_productitem_details
export const measureProductDetailView = (item: ProductDetail): void => {
  const event: DataLayer = {
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: item.name, // Name or ID is required.
          item_id: item.id.toString(),
          price: item.price.toString(),
          item_brand: item.manufacturer.name,
          item_category: item.category.name,
        },
      ],
    },
  };
  pushToDataLayer(event);
};

// @docs https://developers.google.com/tag-manager/ecommerce-ga4#measure_a_checkout
export const measureCheckoutStart = (items: CheckoutBasketItem[]): void => {
  const event: DataLayer = {
    event: 'begin_checkout',
    ecommerce: {
      items: items.map((p) => ({
        item_name: p.name,
        item_id: p.id.toString(),
        price: (p.priceDisplay * p.vatRate).toString(),
        quantity: p.quantity.toString(),
        item_brand: p.manufacturerName,
      })),
    },
  };
  pushToDataLayer(event);
};

export const measureAdditionToBasket = (item: BasketItem, currency: string): void => {
  const event: DataLayer = {
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_id: item.externalId,
          item_name: item.name,
          price: item.price.toString(),
          currency: currency,
          quantity: item.quantity.toString(),
        },
      ],
    },
  };
  pushToDataLayer(event);
};

export const measureRemovalFromBasket = (item: BasketItem, currency: string): void => {
  const event: DataLayer = {
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          item_id: item.externalId,
          item_name: item.name,
          price: item.price.toString(),
          currency: currency,
          quantity: item.quantity.toString(),
        },
      ],
    },
  };
  pushToDataLayer(event);
};
