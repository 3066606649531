import { FC, ReactNode } from 'react';
import cn from 'classnames';

import s from './interactive-table.module.scss';

interface RowCell {
  id: string;
  truncate?: boolean;
  content: ReactNode
}

interface Props {
  cells: RowCell[]
}

const TableRow: FC<Props> = ({ cells }) => {
  return (
    <tr className={s.row}>
      {cells.map((cell) => (
        <td key={cell.id} className={cn(s.cell, { 'text-truncate max-width-xxxxs': cell.truncate })}>
          {cell.content}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;