import { useContext } from 'react';
import { BasketContext, BasketState } from 'contexts/basket';

const useBasket = (): BasketState => {
  const context = useContext(BasketContext);
  if (context === undefined) {
    throw new Error(`useBasket must be used within a BasketProvider`);
  }
  return context;
};

export default useBasket;
