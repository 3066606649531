import React from 'react';

enum ActionTypes {
  Toggle = 'TOGGLE',
  On = 'ON',
  Off = 'OFF',
}

interface ToggleState {
  on: boolean;
}

interface Action {
  type: ActionTypes;
}

const Reducer: React.Reducer<ToggleState, Action> = (state, action) => {
  switch (action.type) {
    case ActionTypes.Toggle: {
      return { on: !state.on };
    }
    case ActionTypes.On: {
      return { on: true };
    }
    case ActionTypes.Off: {
      return { on: false };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
};

export function useToggle(initialState = { on: false }) {
  const [{ on }, dispatch] = React.useReducer<React.Reducer<ToggleState, Action>>(Reducer, initialState);

  const toggle = () => dispatch({ type: ActionTypes.Toggle });
  const setOn = () => dispatch({ type: ActionTypes.On });
  const setOff = () => dispatch({ type: ActionTypes.Off });

  return { on, toggle, setOn, setOff };
}
