import { createContext } from 'react';

import { AuthStateWithFunctions } from './interface';

export const authInitialState: AuthStateWithFunctions = {
  isLoggedIn: false,
  user: null,
  signIn: async () => {},
  signOut: () => {},
  getCurrentUser: () => {},
  updateUserToLocalStorage: () => {},
};

export const AuthContext = createContext<AuthStateWithFunctions>(authInitialState);
AuthContext.displayName = 'AuthContext';
