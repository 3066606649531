import { FC } from 'react';
import dynamic from 'next/dynamic';

// Components
import { Header, Footer, IntlSelectView, TopHeader } from 'components/common';
import { LoadingDots, Modal, Sidebar } from 'components/ui';

// Hooks
import useUI from 'hooks/useUI';

// Interfaces
import { CfSettings } from 'interfaces/Contentful';
import { useRouter } from 'next/router';

const Loading: FC = () => (
  <div className='flex items-center text-center justify-center padding-small' style={{ width: '80px', height: '80px' }}>
    <LoadingDots />
  </div>
);

Loading.displayName = 'Loading';

const dynamicProps = {
  /* eslint-disable react/display-name */
  loading: () => <Loading />,
  ssr: false,
};

const LoginView = dynamic(() => import('components/auth/LoginView'), dynamicProps);
const SignUpView = dynamic(() => import('components/auth/SignUpView'), dynamicProps);
const ForgotPassword = dynamic(() => import('components/auth/ForgotPasswordView'), dynamicProps);
const CookieNotice = dynamic(() => import('components/common/CookieNotice'), dynamicProps);
const MobileNavigation = dynamic(() => import('components/common/MobileNavigation'), dynamicProps);
const Basket = dynamic(() => import('components/basket/Basket'), dynamicProps);
const Search = dynamic(() => import('components/ui/Search'), {
  ssr: false,
  loading: () => null,
});

interface Props {
  pageProps: {
    settings: CfSettings;
  };
}

const Layout: FC<Props> = ({ children, pageProps }) => {
  const {
    basketOpen,
    closeBasket,

    mobileNavOpen,
    closeMobileNav,

    searchOpen,
    closeSearch,

    marketLanguageOpen,
    closeMarketLanguage,

    authView,
    authModalOpen,
    closeAuthModal,
  } = useUI();

  const router = useRouter();

  return (
    <div>
      <TopHeader text={pageProps?.settings?.topText} menu={pageProps?.settings?.topNavigation} />
      <Header menu={pageProps?.settings?.mainNavigation} />
      {router.pathname !== '/search' && <Search open={searchOpen} onClose={closeSearch} />}
      <main role='main'>{children}</main>
      <Footer tag={pageProps?.settings?.footerText} mainNavigation={pageProps?.settings?.mainNavigation} />
      <Sidebar open={mobileNavOpen} onClose={closeMobileNav} position='left'>
        <MobileNavigation
          mainLinks={pageProps?.settings?.mainNavigation}
          topLinks={pageProps?.settings?.topNavigation}
          onClose={closeMobileNav}
        />
      </Sidebar>
      <Sidebar open={basketOpen} onClose={closeBasket}>
        <Basket />
      </Sidebar>
      <Sidebar open={marketLanguageOpen} onClose={closeMarketLanguage}>
        <IntlSelectView />
      </Sidebar>
      <Modal open={authModalOpen} onClose={closeAuthModal}>
        {authView === 'LOGIN_VIEW' && <LoginView />}
        {authView === 'SIGNUP_VIEW' && <SignUpView />}
        {authView === 'FORGOT_VIEW' && <ForgotPassword />}
      </Modal>
      <CookieNotice content={pageProps?.settings?.cookieNotice} />
    </div>
  );
};

Layout.displayName = 'Layout';

export default Layout;
