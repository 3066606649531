import { useContext } from 'react';
import { UiContext, UiStateWithFunctions } from 'contexts/ui';

const useUI = (): UiStateWithFunctions => {
  const context = useContext(UiContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within the UiProvider`);
  }
  return context;
};

export default useUI;
