import React, { FC, Fragment } from 'react';
import { Document as DocumentType, BLOCKS, MARKS } from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
  RenderNode,
  RenderMark,
  RenderText,
} from '@contentful/rich-text-react-renderer';

// Components
import { BlockQuote, Accordion, AccordionItem } from 'components/common';
import { CFQuestion } from 'interfaces/Contentful';

// Simple components
const Bold: FC = ({ children }) => <strong>{children}</strong>;
Bold.displayName = 'Bold';
const TextNode: FC = ({ children }) => <p>{children}</p>;
TextNode.displayName = 'TextNode';

// Marks
const markRendereds: RenderMark = {
  [MARKS.BOLD]: (text): React.ReactNode => <Bold>{text}</Bold>,
};

// Nodes
const nodeRendereds: RenderNode = {
  [BLOCKS.QUOTE]: (_, children) => {
    return <BlockQuote>{children}</BlockQuote>;
  },
  [BLOCKS.EMBEDDED_ENTRY]: (node) => {
    const typeOfEntry = node.data.target?.sys?.contentType?.sys?.id;
    switch (typeOfEntry) {
      case 'faq': {
        const questions: CFQuestion[] = node.data.target.questions;
        return (
          <Accordion key={node.data.target.id}>
            {questions.map((q) => {
              return (
                <AccordionItem key={q.question} title={q.question} defaultOpen={false}>
                  <div className='text-component'>
                    <RichTextContent content={q.answer} />
                  </div>
                </AccordionItem>
              );
            })}
          </Accordion>
        );
      }
    }

    return null;
  },

  [BLOCKS.EMBEDDED_ASSET]: (node) => {
    const url = node.data.target?.fields?.file?.url;
    const typeOfAsset = node.data.target?.fields?.file?.contentType;

    switch (typeOfAsset) {
      case 'image/jpeg': {
        return (
          <div>
            <img className='block width-100% object-cover' src={url}></img>
          </div>
        );
      }
      case 'image/png': {
        return (
          <div>
            <img className='block width-100% object-cover' src={url}></img>
          </div>
        );
      }
    }
    return null;
  },
  [BLOCKS.LIST_ITEM]: (node, _): React.ReactNode => {
    // @ts-ignore
    const UnTaggedChildren = documentToReactComponents(node, {
      renderMark: markRendereds,
      renderNode: {
        ...nodeRendereds,
        [BLOCKS.PARAGRAPH]: (_: any, children: React.ReactNode) => children,
        [BLOCKS.LIST_ITEM]: (_: any, children: React.ReactNode) => children,
      },
      renderText: textRendered,
    });
    return <li>{UnTaggedChildren}</li>;
  },
  [BLOCKS.PARAGRAPH]: (_, children): React.ReactNode => {
    return <TextNode>{children}</TextNode>;
  },
};

// Text
const textRendered: RenderText = (text) => {
  // Convert new lines into <br /> elements
  return text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment];
  }, [] as React.ReactNode[]);
};

const defaultOptions: Options = {
  renderNode: nodeRendereds,
  renderMark: markRendereds,
  renderText: textRendered,
};

interface DocumentProps {
  content?: DocumentType;
  options?: Options;
}

const RichTextContent: FC<DocumentProps> = ({ content, options }) => {
  if (!content) {
    return null;
  }
  const config = {
    ...defaultOptions,
    ...options,
  };
  const element = documentToReactComponents(content, config);
  return <Fragment>{element}</Fragment>;
};

RichTextContent.displayName = 'RichTextContent';

export default RichTextContent;
