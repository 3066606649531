import { AUTH_MODAL_VIEW, UiCookieSettings } from './interface';

export enum ActionTypes {
  OpenBasket = 'BASKET_OPEN',
  CloseBasket = 'BASKET_CLOSE',

  OpenMobileNavigation = 'MOBILE_NAVIGATION_OPEN',
  CloseMobileNavigation = 'MOBILE_NAVIGATION_CLOSE',

  OpenAuthentication = 'AUTHENTICATION_MODAL_OPEN',
  CloseAuthentication = 'AUTHENTICATION_MODAL_CLOSE',
  ChangeAuthenticationView = 'AUTHENTICATION_VIEW_CHANGE',

  OpenMarketLanguageSelector = 'MARKET_LANGUAGE_SELECTOR_OPEN',
  CloseMarketLanguageSelector = 'MARKET_LANGUAGE_SELECTOR_CLOSE',

  OpenSearch = 'SEARCH_OPEN',
  CloseSearch = 'SEARCH_CLOSE',

  OpenCookieNotice = 'COOKIE_NOTICE_OPEN',
  CloseCookieNotice = 'COOKIE_NOTICE_CLOSE',
  ChangeCookieNoticeSettings = 'COOKIE_NOTICE_CHANGE',
}

export interface BasketOpen {
  type: ActionTypes.OpenBasket;
}

export interface BasketClose {
  type: ActionTypes.CloseBasket;
}

export interface OpenMobileNavigation {
  type: ActionTypes.OpenMobileNavigation;
}

export interface CloseMobileNavigation {
  type: ActionTypes.CloseMobileNavigation;
}

export interface OpenAuthentication {
  type: ActionTypes.OpenAuthentication;
  payload: { view?: AUTH_MODAL_VIEW };
}

export interface CloseAuthentication {
  type: ActionTypes.CloseAuthentication;
}

export interface ChangeAuthenticationView {
  type: ActionTypes.ChangeAuthenticationView;
  payload: { view: AUTH_MODAL_VIEW };
}

export interface OpenMarketLanguageSelector {
  type: ActionTypes.OpenMarketLanguageSelector;
}

export interface CloseMarketLanguageSelector {
  type: ActionTypes.CloseMarketLanguageSelector;
}

export interface OpenSearch{
  type: ActionTypes.OpenSearch;
}

export interface CloseSearch{
  type: ActionTypes.CloseSearch;
}

export interface OpenCookieNotice {
  type: ActionTypes.OpenCookieNotice;
}

export interface CloseCookieNotice {
  type: ActionTypes.CloseCookieNotice;
}

export interface ChangeCookieNoticeSettings {
  type: ActionTypes.ChangeCookieNoticeSettings;
  payload: { settings: UiCookieSettings };
}

export type UiAction =
  | BasketOpen
  | BasketClose
  | OpenMobileNavigation
  | CloseMobileNavigation
  | OpenAuthentication
  | CloseAuthentication
  | ChangeAuthenticationView
  | OpenMarketLanguageSelector
  | CloseMarketLanguageSelector
  | OpenSearch
  | CloseSearch
  | OpenCookieNotice
  | CloseCookieNotice
  | ChangeCookieNoticeSettings;
