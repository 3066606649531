import { UiState } from './interface';
import { UiAction, ActionTypes } from './actions';

export default function uiReducer(state: UiState, action: UiAction): UiState {
  switch (action.type) {
    // Basket
    case ActionTypes.OpenBasket: {
      return {
        ...state,
        basketOpen: true,
      };
    }

    case ActionTypes.CloseBasket: {
      return {
        ...state,
        basketOpen: false,
      };
    }

    // Mobile Navigation
    case ActionTypes.OpenMobileNavigation: {
      return {
        ...state,
        mobileNavOpen: true,
      };
    }

    case ActionTypes.CloseMobileNavigation: {
      return {
        ...state,
        mobileNavOpen: false,
      };
    }

    // Authentication
    case ActionTypes.OpenAuthentication: {
      return {
        ...state,
        authModalOpen: true,
        authView: action.payload.view ? action.payload.view : 'LOGIN_VIEW',
      };
    }

    case ActionTypes.CloseAuthentication: {
      return {
        ...state,
        authModalOpen: false,
      };
    }

    case ActionTypes.ChangeAuthenticationView: {
      return {
        ...state,
        authView: action.payload.view,
      };
    }

    // Market/Language selector
    case ActionTypes.OpenMarketLanguageSelector: {
      return {
        ...state,
        marketLanguageOpen: true,
      };
    }

    case ActionTypes.CloseMarketLanguageSelector: {
      return {
        ...state,
        marketLanguageOpen: false,
      };
    }

      // Search
      case ActionTypes.OpenSearch: {
        return {
          ...state,
          searchOpen: true,
        };
      }
  
      case ActionTypes.CloseSearch: {
        return {
          ...state,
          searchOpen: false,
        };
      }

    // Cookie notice
    case ActionTypes.OpenCookieNotice: {
      return {
        ...state,
        cookieNoticeOpen: true,
      };
    }

    case ActionTypes.CloseCookieNotice: {
      return {
        ...state,
        cookieNoticeOpen: false,
      };
    }

    case ActionTypes.ChangeCookieNoticeSettings: {
      return {
        ...state,
        cookiesSettings: {
          ...state.cookiesSettings,
          ...action.payload.settings,
        },
      };
    }

    default:
      return state;
  }
}
