import { useRouter } from 'next/router';

export function useI18n() {
  const router = useRouter();
  const locale = router.locale as string;
  const locales = router.locales as string[];
  const defaultLocale = router.defaultLocale as string;

  function getCurrency(locale: string): string {
    switch (locale) {
      case 'sv':
      case 'sv-SE':
        return 'SEK';
      case 'en':
        return 'EUR';
      case 'en-GB':
        return 'GBP';
      case 'en-US':
      default:
        return 'USD';
    }
  }

  function getLanguage(locale: string): string {
    switch (locale) {
      case 'sv':
      case 'sv-SE':
        return 'Svenska';
      case 'en':
      case 'en-GB':
      case 'en-US':
      case 'sv-EN':
      default:
        return 'English';
    }
  }

  function getRegion(locale: string): string {
    switch (locale) {
      case 'sv':
      case 'sv-SE':
        return 'Sverige';
      case 'en':
      case 'en-GB':
        return 'Great Britain';
      case 'en-US':
      default:
        return 'USA';
    }
  }

  const currency = getCurrency(locale);

  return {
    locale,
    locales,
    defaultLocale,
    language: getLanguage(locale),
    region: getRegion(locale),
    currency,
    getCurrency,
    getLanguage,
    getRegion,
  };
}
