import { FC, MouseEvent } from 'react';
import cn from 'classnames';

import s from './interactive-table.module.scss';

interface Props {
  id: string;
  title: string;
  sortable?: boolean;
  textAlignment?: 'left' | 'center' | 'right';
  onClick?: (key: string) => void
}

const TableHead: FC<Props> = ({ id, title, sortable, textAlignment, onClick }) => {

  const handleOnClick = (event: MouseEvent<HTMLTableHeaderCellElement>) => {
    if (onClick) {
      event.preventDefault();
      onClick(id);
    }
  }

  const classes = cn(s.cell, s['cell--th'], {
    [s['cell--sort']]: sortable === true,
    'text-left': textAlignment === 'left' || !sortable,
    'text-center': textAlignment === 'center',
    'text-right': textAlignment === 'right',
  });

  if (!sortable) {
    return (
      <th className={classes}>
        <span className='table-head'>{title}</span>
      </th>
    );
  }

  return (
    <th className={classes} onClick={handleOnClick}>
      <div className='flex items-center'>
        <span className='table-head'>{title}</span>
        <svg className={cn(s['sort-icon'], 'icon icon--xxs margin-left-xxxs')} aria-hidden='true' viewBox='0 0 12 12'>
          <polygon className='arrow-up' points='6 0 10 5 2 5 6 0' />
          <polygon className='arrow-down' points='6 12 2 7 10 7 6 12' />
        </svg>
      </div>
    </th>
  );
};

export default TableHead;