import { Basket } from 'interfaces/Storm/Basket';

export enum ActionTypes {
  UpdateBasket = 'Basket/Update',
  EmptyBasket = 'Basket/Empty'
}

interface UpdateBasketAction {
  type: ActionTypes.UpdateBasket;
  payload: {
    basket: Basket;
  };
}
interface EmptyBasketAction {
  type: ActionTypes.EmptyBasket;
}

export type Action = UpdateBasketAction | EmptyBasketAction;
