import { ContentTypes } from 'interfaces/Contentful';

export function removeFromLast(path: string, key: string): string {
  const i = path.lastIndexOf(key);
  return i === -1 ? path : path.substring(0, i);
}

export function getUrlForContentType(href: string, contentType: string): string {
  let url = '';
  switch (contentType) {
    case ContentTypes.CategoryPage:
      url = `/category/${href}`;
      break;
    case ContentTypes.ArticlePage:
      url = `/articles/${href}`;
      break;
    case ContentTypes.EditorialPage:
    default:
      url = href;
      break;
  }

  if (!url.startsWith('/')) {
    return '/' + url;
  }

  return url;
}

export function createSlugFromParams(url: string | string[]): string {
  if (Array.isArray(url)) {
    // Join by , beacuse of endpoint not being hit if / are used
    return `${url.join(',')}`;
  }
  return `${url}`;
}
