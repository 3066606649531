import cn from 'classnames';
import { FC } from 'react';

import s from './tabs.module.scss';

interface Props {
  id: string;
  selected: boolean;
  innerClassnames?: string;
}

const TabContent: FC<Props> = ({ selected = false, children, innerClassnames, ...other }) => {
  const classnames = cn(s.content, {
    [s.contentHidden]: !selected,
  });

  return (
    <div className={`flex justify-center ${classnames}`} tabIndex={0} {...other}>
      <div className={innerClassnames}>{children}</div>
    </div>
  );
};

export default TabContent;
