import { FC, ReactNode, Children, cloneElement, ReactElement } from 'react';
import { AnimatePresence, m as motion } from 'framer-motion';
import cn from 'classnames';

// Styles
import s from './accordion.module.scss';

// Hooks
import { useToggle } from 'hooks/useToggle';

export interface AccordionItemProps {
  id?: string | number;
  title: string;
  subTitle?: string | ReactNode;
  defaultOpen?: boolean;
  children: ReactNode | ReactNode[];
  className?: string;
}

export const AccordionItem: FC<AccordionItemProps> = ({
  id,
  title,
  subTitle,
  defaultOpen = true,
  children,
  className,
}) => {
  const { on, toggle } = useToggle({ on: defaultOpen });

  const handleOnToggleOpen = () => {
    toggle();
  };

  const classes = cn(s.accordionItem, className, {
    [s.open]: on,
  });

  return (
    <li className={classes}>
      <button
        className={s.trigger}
        type='button'
        aria-expanded={on}
        aria-controls={`accordion-content-${id}`}
        id={`accordion-header-${id}`}
        onClick={handleOnToggleOpen}
      >
        <div>
          <span className={s.title}>{title}</span>
          {subTitle && (
            <div className={s.subtitle}>
              <span>{subTitle}</span>
            </div>
          )}
        </div>
        <svg className={cn('icon', 'icon--xs', s.iconArrow)} viewBox='0 0 16 16' aria-hidden='true'>
          <g className={s.iconGroup} fill='none' stroke='currentColor' strokeLinecap='square' strokeMiterlimit='10'>
            <path d='M2 2l12 12' />
            <path d='M14 2L2 14' />
          </g>
        </svg>
      </button>

      <div aria-labelledby={`accordion-header-${id}`} id={`accordion-content-${id}`}>
        <AnimatePresence initial={defaultOpen}>
          {on && (
            <motion.div
              className='overflow-hidden'
              key='content'
              initial='open'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <div className='padding-sm'>{children}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </li>
  );
};

export const Accordion: FC = ({ children }) => {
  const childs = Children.toArray(children);
  return (
    <ul className={s.accordion}>
      {childs.map((child, i) => cloneElement(child as ReactElement<AccordionItemProps>, { id: i + 1 }))}
    </ul>
  );
};
