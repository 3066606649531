import { FC, memo } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import styles from './avatar.module.scss';

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  src?: string;
  alt?: string;
  className?: string;
}

const Avatar: FC<Props> = ({ size = 'md', src, alt, className }) => {
  const { formatMessage } = useIntl();
  const classes = classNames(styles.avatar, className, {
    [styles[size]]: size,
  });

  const altText =
    alt ||
    formatMessage({
      id: 'avatar.altTextFallback',
      defaultMessage: 'Avatar',
      description: 'Descriptive text for an avatar',
    });

  return (
    <div className={classes}>
      <figure className={styles.figure} role='img' aria-label={altText}>
        {!src && (
          <svg viewBox='0 0 20 20' className={styles.placeholder}>
            <g fill='none' fillRule='evenodd' stroke='currentColor'>
              <circle cx='10' cy='5' r='4.5' />
              <path d='M14 11.5a5.49 5.49 0 013.89 1.61A5.49 5.49 0 0119.5 17h0v2.402l-19 .1V17a5.49 5.49 0 011.61-3.89A5.49 5.49 0 016 11.5h0z' />
            </g>
          </svg>
        )}
        {src && <img className='img' src={src} alt={altText} />}
      </figure>
    </div>
  );
};

export default memo(Avatar, (prevProps, nextProps) => {
  if (nextProps.src !== prevProps.size) {
    return true;
  } else if (nextProps.size !== prevProps.size) {
    return true;
  }
  return false;
});
