import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './pagination.module.scss';

interface Props {
  totalPages: number;
  pageNeighbours?: 0 | 1 | 2;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

interface ItemProps {
  page: number;
  active: boolean;
  onClick: (page: number) => void;
}

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const EXTRA_PAGE = 'EXTRA';

type PaginationText = typeof LEFT_PAGE | typeof RIGHT_PAGE | typeof EXTRA_PAGE | number;

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const PaginationItem: FC<ItemProps> = ({ page, active, onClick }) => {
  const { formatMessage } = useIntl();

  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!active) {
      onClick(page);
    }
  };

  return (
    <li className={`display@sm`}>
      <a
        href={'#0'}
        className={cn(styles.item, { [styles.selected]: active })}
        aria-label={formatMessage({ id: 'pagination.label', defaultMessage: 'Go to page {page}' }, { page: page })}
        onClick={handleOnClick}
      >
        {page}
      </a>
    </li>
  );
};

const Pagination: FC<Props> = ({ totalPages, pageNeighbours = 1, currentPage = 1, onPageChange }) => {
  const { formatMessage } = useIntl();

  const handleOnPageChange = (page: number) => {
    onPageChange(page);
  };

  const handleOnPreviousClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      onPageChange(prevPage);
    }
  };

  const handleOnNextClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      onPageChange(nextPage);
    }
  };

  const createPagination = useCallback(
    (totalPages: number, pageNeighbours: number): PaginationText[] => {
      let pages: PaginationText[] = [];

      /**
       * totalNumbers: the total page numbers to show on the control
       * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
       */
      const totalNumbers = pageNeighbours * 2 + 3;
      const totalBlocks = totalNumbers + 1;

      if (totalPages > totalBlocks) {
        const startPage = Math.max(1, currentPage - pageNeighbours);
        const endPage = Math.min(totalPages, currentPage + pageNeighbours);
        pages = range(startPage, endPage);

        /**
         * hasLeftSpill: has hidden pages to the left
         * hasRightSpill: has hidden pages to the right
         * spillOffset: number of hidden pages either to the left or to the right
         */
        const hasLeftSpill = startPage > 2;
        const hasRightSpill = totalPages - endPage >= 1;

        switch (true) {
          // handle: (1) < {5 6} [7] {8 9} (10)
          case hasLeftSpill && !hasRightSpill: {
            pages = [LEFT_PAGE, EXTRA_PAGE, ...pages];
            break;
          }

          // handle: (1) {2 3} [4] {5 6} > (10)
          case !hasLeftSpill && hasRightSpill: {
            pages = [LEFT_PAGE, ...pages, EXTRA_PAGE, totalPages, RIGHT_PAGE];
            break;
          }

          // handle: (1) < {4 5} [6] {7 8} > (10)
          case hasLeftSpill && hasRightSpill:
          default: {
            pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
            break;
          }
        }

        return pages;
      }

      pages = range(1, totalPages);
      return [LEFT_PAGE, ...pages, RIGHT_PAGE];
    },
    [currentPage]
  );

  const memoizedPagination = useMemo(() => {
    return createPagination(totalPages, pageNeighbours);
  }, [createPagination, totalPages, pageNeighbours]);

  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <nav
      className={styles.pagination}
      aria-label={formatMessage({ id: 'pagination.title', defaultMessage: 'Pagination' })}
    >
      <ol className={`${styles.list} flex flex-wrap gap-xs justify-center`}>
        {memoizedPagination.map((page) => {
          if (page === LEFT_PAGE) {
            if (currentPage === 1) {
              return null;
            }

            return (
              <li key={LEFT_PAGE}>
                <a
                  href='#0'
                  className={styles.item}
                  aria-label={formatMessage({ id: 'pagination.goToPrevious', defaultMessage: 'Go to previous page' })}
                  onClick={handleOnPreviousClick}
                >
                  <svg className={`icon margin-right-xxxs`} aria-hidden='true' viewBox='0 0 16 16'>
                    <title>{formatMessage({ id: 'pagination.previousTitle', defaultMessage: 'Previous' })}</title>
                    <g strokeWidth='1' stroke='currentColor'>
                      <polyline
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeMiterlimit='10'
                        points='9.5,3.5 5,8 9.5,12.5 '
                      ></polyline>
                    </g>
                  </svg>
                  <span>{formatMessage({ id: 'pagination.previousShorthand', defaultMessage: 'Prev' })}</span>
                </a>
              </li>
            );
          } else if (page === RIGHT_PAGE) {
            if (currentPage + 1 > totalPages) {
              return null;
            }

            return (
              <li key={RIGHT_PAGE}>
                <a
                  href='#0'
                  className={styles.item}
                  aria-label={formatMessage({ id: 'pagination.goToNext', defaultMessage: 'Go to next page' })}
                  onClick={handleOnNextClick}
                >
                  <span>{formatMessage({ id: 'pagination.nextShorthand', defaultMessage: 'Next' })}</span>
                  <svg className={`icon margin-left-xxxs`} aria-hidden='true' viewBox='0 0 16 16'>
                    <title>{formatMessage({ id: 'pagination.nextTitle', defaultMessage: 'Next' })}</title>
                    <g strokeWidth='1' stroke='currentColor'>
                      <polyline
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeMiterlimit='10'
                        points='6.5,3.5 11,8 6.5,12.5 '
                      ></polyline>
                    </g>
                  </svg>
                </a>
              </li>
            );
          } else if (page === EXTRA_PAGE) {
            return (
              <li className='display@sm' aria-hidden='true' key={EXTRA_PAGE}>
                <span className={`${styles.item} ${styles.ellipsis}`}>...</span>
              </li>
            );
          }
          return (
            <PaginationItem
              key={`pagination-${page}`}
              page={page}
              active={page === currentPage}
              onClick={handleOnPageChange}
            />
          );
        })}
      </ol>
    </nav>
  );
};

export default Pagination;
