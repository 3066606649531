import { FC } from 'react';
import Link from 'next/link';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import Image from 'next/image';

// Styles
import styles from './product-relations.module.scss';

// Hooks
import { useI18n } from 'hooks/useI18n';
import useBasket from 'hooks/useBasket';

// Types
import useAuth from 'hooks/useAuth';
import { Fragment } from 'react';
import { ProductRelations } from 'interfaces/Storm/ProductDetail';


interface Props {
  productR: ProductRelations;
  aspectRatio?: '1:1' | '2:3';
}

const ProductRelationsCards: FC<Props> = ({ productR, aspectRatio = '2:3' }) => {
  const { currency } = useI18n();
  const { addProduct } = useBasket();
  const { isLoggedIn } = useAuth();
  const handleOnAddToCart = () => {
    addProduct(productR.partNo, 1);
  };
  const imageWidth = aspectRatio === '1:1' ? '800' : '540';
  const imageHeight = aspectRatio === '1:1' ? '800' : '380';

  const action = isLoggedIn ? (
    <Fragment>
      <span className={styles.price}>
        <FormattedNumber value={productR.price} style={'currency'} currency={currency} />
      </span>
      <p className={styles.vat}>
        <FormattedMessage id='product-card.excludeVat' defaultMessage='Exkl. moms' />
      </p>
      <div className='margin-top-sm'>
        <button className='btn btn--primary width-100%' onClick={handleOnAddToCart}>
          <FormattedMessage id='product-view.addToCartButton' defaultMessage='Add to cart' />
        </button>
      </div>
    </Fragment>
  ) : (
    <div className='text-component text-sm font-italic text-center'>
      <p>
        <FormattedMessage id='product-view.require-login' defaultMessage='You must login to see the price' />
      </p>
    </div>
  );

  const url = new URL(productR.primaryImageUrl);

  return (
    <div className={`col-12 col-4@sm col-3@md ${styles.card}`}>
      <Link href={`/product/${productR.uniqueName}`} passHref>
        <a className={styles.imageLink} title={productR.name}>
          {productR.primaryImageUrl && url.pathname !== '/' ? (
            <Image
              src={productR.primaryImageUrl}
              alt={productR.name || 'Product Image'}
              width={imageWidth}
              height={imageHeight}
              quality='85'
              objectFit='contain' 
            />
          ): 
            <Image
              src='/defaultW.png'
              alt='default-image'
              width={imageWidth}
              height={imageHeight}
              quality='85'
              objectFit='contain' 
            />
          }
        </a>
      </Link>

      <div className={styles.content}>
        <div className='flex-grow'>
        <p className='color-secondary text-sm margin-bottom-xs'>{productR.manufacturer?.name}</p>
        <Link href={`/product/${productR.uniqueName}`} passHref>
          <a className={styles.title} title={productR.name}>
            {productR.name}
          </a>
        </Link>

        <div
          className={`${styles.excerpt} line-height-lg`}
          dangerouslySetInnerHTML={{ __html: productR.description || '' }}
        />
        </div>
        {action}
      </div>
    </div>
  );
};

export default ProductRelationsCards;