import { useHits } from "react-instantsearch-hooks";
import useSWR from "swr";

// Services
import { getProductsByPartNos } from "services/storm";

// Interfaces
import { AlgoliaProductModel } from "interfaces/Algolia";
import useAuth from "./useAuth";

const fetcher = async (_: string, partNos: string) => getProductsByPartNos(partNos).then((res) => res.data);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useHitsWithCustomerPrices = () => {
    const { isLoggedIn } = useAuth();
    const { hits } = useHits<AlgoliaProductModel>();

    // Only check prices if user is logged in and there are hits
    const { data, error } = useSWR(isLoggedIn && hits && hits.length > 0 ? ['/api/products/getProductsByPartNos', hits.map(hit => hit.partNo).toString()] : null, fetcher);

    const getHitsWithCustomerPrices = () => {
        if (!data) {
            return hits;
        } else {
            return hits.map(hit => {
                const match = data.items.find((product) => product.partNo === hit.partNo);
                if (match) {
                    hit.price = match.price;
                }
                return hit;
            })
        }
    }

    return {
        hits: getHitsWithCustomerPrices(),
        error,
        pricesAreLoading: !data && !error,
    }
};

export default useHitsWithCustomerPrices;