import algoliasearch, { SearchClient } from 'algoliasearch';

const baseSearchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY || ''
);

const searchFn = baseSearchClient.search.bind(baseSearchClient);

// Search client
const modifiedAlgoliaSearchClient: SearchClient = {
  ...baseSearchClient,
  // search(requests) {
  //   if (requests.every(({ params }) => !params?.query)) {
  //     return Promise.resolve({
  //       results: requests.map(() => ({
  //         hits: [],
  //         nbHits: 0,
  //         nbPages: 0,
  //         page: 0,
  //         processingTimeMS: 0,
  //         hitsPerPage: 0,
  //         exhaustiveNbHits: false,
  //         query: '',
  //         params: '',
  //       })),
  //     });
  //   }
  //   return searchFn(requests);
  // },
};

const algoliaSearchClient: SearchClient = {
  ...baseSearchClient,
};

// Index names
const algoliaMainIndex = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || '';
const algoliaQuerySuggestionsIndex = process.env.NEXT_PUBLIC_ALGOLIA_QUERY_SUGGESTIONS_INDEX_NAME || '';

export { modifiedAlgoliaSearchClient, algoliaSearchClient, algoliaMainIndex, algoliaQuerySuggestionsIndex };