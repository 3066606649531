import { FC, useReducer, useCallback, useMemo, useEffect } from 'react';
import Cookies from 'js-cookie';

// Context
import UiContext from './context';
import UiReducer from './reducer';
import { initialUiState } from './initialState';
import { ActionTypes } from './actions';
import { AUTH_MODAL_VIEW, UiCookieSettings } from './interface';

const COOKIE_NAME = 'accept_cookies';
const COOKIE_VALID_LENGTH = 365; // as in days

const UiProvider: FC = (props) => {
  const [state, dispatch] = useReducer(UiReducer, initialUiState);

  // On component mount
  useEffect(() => {
    // Check if there are any saved settings
    function checkSavedCookies() {
      const savedCookie = Cookies.get(COOKIE_NAME);

      if (savedCookie) {
        const parsed = JSON.parse(savedCookie);
        dispatch({
          type: ActionTypes.ChangeCookieNoticeSettings,
          payload: {
            settings: parsed,
          },
        });
      } else {
        dispatch({ type: ActionTypes.OpenCookieNotice });
      }
    }

    if (typeof window !== 'undefined') {
      checkSavedCookies();
    }
  }, []);

  // Basket
  const openBasket = () => dispatch({ type: ActionTypes.OpenBasket });
  const closeBasket = () => dispatch({ type: ActionTypes.CloseBasket });
  const toggleBasket = useCallback(() => {
    if (state.basketOpen) {
      dispatch({ type: ActionTypes.CloseBasket });
    } else {
      dispatch({ type: ActionTypes.OpenBasket });
    }
  }, [state.basketOpen]);

  // Mobile navigation
  const openMobileNav = () => dispatch({ type: ActionTypes.OpenMobileNavigation });
  const closeMobileNav = () => dispatch({ type: ActionTypes.CloseMobileNavigation });
  const toggleMobileNav = useCallback(() => {
    if (state.mobileNavOpen) {
      dispatch({ type: ActionTypes.CloseMobileNavigation });
    } else {
      dispatch({ type: ActionTypes.OpenMobileNavigation });
    }
  }, [state.mobileNavOpen]);

  // Authentication modal
  const openAuthModal = (view?: AUTH_MODAL_VIEW) =>
    dispatch({ type: ActionTypes.OpenAuthentication, payload: { view } });
  const closeAuthModal = () => dispatch({ type: ActionTypes.CloseAuthentication });
  const setAuthModalView = (view: AUTH_MODAL_VIEW) =>
    dispatch({ type: ActionTypes.ChangeAuthenticationView, payload: { view } });

  // Market/Language selector
  const openMarketLanguage = () => dispatch({ type: ActionTypes.OpenMarketLanguageSelector });
  const closeMarketLanguage = () => dispatch({ type: ActionTypes.CloseMarketLanguageSelector });

  // Search
  const openSearch = () => dispatch({ type: ActionTypes.OpenSearch });
  const closeSearch = () => dispatch({ type: ActionTypes.CloseSearch });
  const toggleSearch = useCallback(() => {
    if (state.searchOpen) {
      dispatch({ type: ActionTypes.CloseSearch });
    } else {
      dispatch({ type: ActionTypes.OpenSearch });
    }
  }, [state.searchOpen]);

  // Cookie Notice
  const openCookieNotice = () => dispatch({ type: ActionTypes.OpenCookieNotice });
  const closeCookieNotice = () => dispatch({ type: ActionTypes.CloseCookieNotice });
  const toggleCookieNotice = () =>
    useCallback(() => {
      if (state.cookieNoticeOpen) {
        dispatch({ type: ActionTypes.CloseCookieNotice });
      } else {
        dispatch({ type: ActionTypes.OpenCookieNotice });
      }
    }, [state.cookieNoticeOpen]);
  const changeCookieSettings = (settings: UiCookieSettings) => {
    if (typeof window !== 'undefined') {
      Cookies.set(COOKIE_NAME, JSON.stringify(settings), { expires: COOKIE_VALID_LENGTH });
    }
    dispatch({ type: ActionTypes.ChangeCookieNoticeSettings, payload: { settings } });
  };

  const value = useMemo(
    () => ({
      ...state,

      openBasket,
      closeBasket,
      toggleBasket,

      openMobileNav,
      closeMobileNav,
      toggleMobileNav,

      openAuthModal,
      closeAuthModal,
      setAuthModalView,

      openMarketLanguage,
      closeMarketLanguage,

      openSearch,
      closeSearch,
      toggleSearch,

      openCookieNotice,
      closeCookieNotice,
      toggleCookieNotice,
      changeCookieSettings,
    }),
    [state, toggleBasket, toggleMobileNav, toggleSearch]
  );

  return <UiContext.Provider value={value} {...props} />;
};

export default UiProvider;
