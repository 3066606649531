import axios, { AxiosError } from 'axios';
import useSWR from 'swr';

// Interfaces
import { GetProductsResponse } from 'interfaces/Storm/Responses/ProductResponses';
import { ProductListItem } from 'interfaces/Storm/Product';

const fetcher = () => axios.get<GetProductsResponse>('/api/products/popular').then((res) => res.data);

interface UsePopularProducts {
  products: ProductListItem[];
  count: number;
  isLoading: boolean;
  error?: AxiosError;
}

function usePopularProducts(): UsePopularProducts {
  const { data, error } = useSWR('api/products/popular-products', fetcher);
  return {
    products: data?.items || [],
    count: data?.itemCount || 0,
    isLoading: !data && !error,
    error,
  };
}

export default usePopularProducts;
