import { FC, useRef, useEffect, useCallback } from 'react';
import Portal from '@reach/portal';
import { AnimatePresence, m as motion, LazyMotion, domAnimation } from 'framer-motion';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// Styling
import s from './Modal.module.scss';

// Components
import { Cross } from 'components/icons';

interface Props {
  className?: string;
  open?: boolean;
  onClose: () => void;
}

const Modal: FC<Props> = ({ children, open = false, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current);
        window.addEventListener('keydown', handleKey);
      } else {
        enableBodyScroll(ref.current);
      }
    }
    return () => {
      window.removeEventListener('keydown', handleKey);
      clearAllBodyScrollLocks();
    };
  }, [open, handleKey]);
  
  const { formatMessage } = useIntl();

  const handleOnClose = () => {
    onClose();
  };

  const ariaTitle = formatMessage({
    id: 'modal.closeButton',
    defaultMessage: 'Close modal window',
    description: 'Title and label for the button which closes the modal window',
  });

  return (
    <Portal>
      <AnimatePresence initial={false}>
        {open ? (
          <div className={s.root}>
            <LazyMotion features={domAnimation}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
              >
                <div className={s.modal} ref={ref}>
                  <div className={s.content}>{children}</div>
                  <button onClick={handleOnClose} aria-label={ariaTitle} className={cn('reset', s.closeButton)}>
                    <Cross className={cn('icon', 'icon--sm', s.icon)} title={ariaTitle} />
                  </button>
                </div>
              </motion.div>
            </LazyMotion>
          </div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;
