import { FC, useState } from 'react';
import RangeSlider from 'react-slider';
import cn from 'classnames';

import s from './range-slider.module.scss';

interface Props {
  prefix?: string;
  min: number;
  max: number;
  value: State;
  step?: number;
  ariaLabels: string[];
  onChange: (state: State) => void;
}

interface State {
  min: number;
  max: number;
}

const getTrackClassName = (index: number) => {
  switch (index) {
    case 0:
      return s.trackOne;
    case 1:
      return s.trackTwo;
    case 2:
      return s.trackThree;
    default:
      return '';
  }
};

const MultiValueRangeSlider: FC<Props> = ({ min = 0, max, value, step = 10, ariaLabels, onChange }) => {
  const [reflectedValue, setReflectedValue] = useState({
    min: value.min || min,
    max: value.max || max,
  });

  const handleOnChange = (value: number[]) => {
    const [min, max] = value;
    setReflectedValue({ min, max });
  };

  const handleOnAfterChange = (value: number[]) => {
    const [min, max] = value;
    onChange({ min, max });
  };

  const defaultValue = [min, max];

  return (
    <div>
      <div className={s.labels}>
        <p>{reflectedValue.min}</p>
        <p>{reflectedValue.max}</p>
      </div>
      <RangeSlider
        className={s.slider}
        thumbClassName={s.thumb}
        defaultValue={defaultValue}
        min={min}
        max={max}
        ariaLabel={ariaLabels}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderTrack={({ className, ...rest }, state) => (
          <div className={cn(s.track, getTrackClassName(state.index))} {...rest} />
        )}
        renderThumb={(props, state) => (
          <div {...props}>
            <span className='sr-only'>{state.valueNow}</span>
          </div>
        )}
        pearling
        minDistance={1}
        step={step}
        onChange={handleOnChange}
        onAfterChange={handleOnAfterChange}
      />
    </div>
  );
};

export default MultiValueRangeSlider;
