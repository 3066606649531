import { FC, Fragment, useState, MouseEvent, useEffect } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import s from './side-nav.module.scss';

// Hooks
import { useRouter } from 'next/router';

interface LinkItem {
  title: string;
  href: string;
  counter?: string;
}

interface LinkGroup {
  label?: string;
  links: LinkItem[];
}

interface Props {
  currentPage: string;
  links: LinkGroup[];
}

const SideNav: FC<Props> = ({ currentPage, links }) => {
  const [isStatic, setIsStatic] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    function checkMediaQuery() {
      if (typeof window !== 'undefined') {
        const isMdOrLarger = window.matchMedia('(min-width: 992px)').matches;
        setIsStatic(isMdOrLarger);
      }
    }

    function onResizeChange() {
      checkMediaQuery();
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResizeChange, { passive: true });
    }

    checkMediaQuery();

    return () => {
      window.removeEventListener('resize', onResizeChange);
    };
  }, [setIsStatic]);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const classes = cn(s.root, {
    [s.static]: isStatic,
    [s.expanded]: isExpanded,
  });

  return (
    <div className={classes}>
      <button className={cn(s.control, 'reset')} aria-controls='sidenav' onClick={handleOnClick}>
        <span className={s.controlText}>
          <i>
            {currentPage} <span className='sr-only'>- press button to select new page.</span>
          </i>
          <i aria-hidden='true'>Select</i>
        </span>
        <svg className={cn(s.iconArrow, 'icon margin-left-xxxs')} viewBox='0 0 16 16' aria-hidden='true'>
          <g className={s.iconGroup} fill='none' stroke='currentColor' strokeLinecap='square' strokeMiterlimit={10}>
            <path d='M2 2l12 12' />
            <path d='M14 2L2 14' />
          </g>
        </svg>
      </button>
      <nav className={cn(s.nav, 'font-smooth')} id='sidenav'>
        {links.map((linkGroup, index) => {
          if (linkGroup.label) {
            return (
              <Fragment key={linkGroup.label}>
                <div className={s.label}>
                  <span>{linkGroup.label}</span>
                </div>
                <ul className={cn(s.list, 'grid gap-xxxxs')}>
                  {linkGroup.links.map((l) => (
                    <li key={l.href}>
                      <Link href={l.href} passHref>
                        <a className={cn(s.link, 'js-tab-focus')}>
                          <span className={s.linkText}>{l.title}</span>
                          {l.counter && (
                            <span className={s.counter}>
                              {l.counter} <i className='sr-only'>notifications</i>
                            </span>
                          )}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Fragment>
            );
          }

          return (
            <ul key={index} className={cn(s.list, 'grid gap-xxxxs')}>
              {linkGroup.links.map((l) => (             
                <li key={l.href}>
                  <Link href={l.href} passHref>
                    <a  className={cn(s.link, router.pathname === l.href  ? s['link--active'] : null, 'js-tab-focus')}>
                      <span className={s.linkText}>{l.title}</span>
                      {l.counter && (
                        <span className={s.counter}>
                          {l.counter} <i className='sr-only'>notifications</i>
                        </span>
                      )}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          );
        })}
      </nav>
    </div>
  );
};

export default SideNav;
