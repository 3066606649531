import { FC, memo } from 'react';
import Link from 'next/link';
import { useIntl } from 'react-intl';

const Logotype: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Link href={'/'} passHref>
      <a title={formatMessage({ id: 'logotype.linkTitle', defaultMessage: 'Go to homepage' })}>
          <img src='/images/logo.png' />
      </a>
    </Link>
  );
};

export default memo(Logotype);
