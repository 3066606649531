import { FC } from 'react';
import { IconProps } from './IconProps';

const Success: FC<IconProps> = (props) => {
  return (
    <svg aria-hidden='true' viewBox='0 0 32 32' {...props}>
      {props.title && <title>{props.title}</title>}
      <g>
        <path d='M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M23.7,11.7l-10,10C13.5,21.9,13.3,22,13,22 s-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.3,3.3l9.3-9.3c0.4-0.4,1-0.4,1.4,0S24.1,11.3,23.7,11.7z'></path>
      </g>
    </svg>
  );
};

Success.defaultProps = {
  className: 'icon',
};

export default Success;
