import { FC } from 'react';
import { useIntl } from 'react-intl';

// Algolia
import { useInstantSearch } from 'react-instantsearch-hooks';

// Components
import AlgoliaProductCard from '../AlgoliaProductCard';
import { CircleLoader } from 'components/ui';

// Hooks
import useHitsWithCustomerPrices from 'hooks/useHitsWithCustomerPrices';

const AlgoliaSearchHits: FC = () => {
  const { formatMessage } = useIntl();
  const { status }  = useInstantSearch();
  const { hits, pricesAreLoading } = useHitsWithCustomerPrices();


  if (hits.length === 0 && status !== 'loading') {
    return <p>{formatMessage({ id: 'search-page.no-results', defaultMessage: 'No results were found' })}</p>;
  }

  if (hits.length === 0 && status === 'loading') {
    return <CircleLoader size="sm" />
  }
  
  return (
    <div className='grid gap-sm'>
      {hits.map((product) => (
        <div key={product.partNo} className='col-12 col-6@xs col-4@md'>
          <AlgoliaProductCard product={product} pricesAreLoading={pricesAreLoading} />
        </div>
      ))}
    </div>
  );
};

export default AlgoliaSearchHits;
