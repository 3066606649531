import { FC, useState } from 'react';
import Link from 'next/link';

// Styles
import styles from './megaMenu.module.scss';

// Inteface
import { CfReferenceLink } from 'interfaces/Contentful';

// Utils
import getCategoryName from 'utils/getCategoryName';
import { getUrlForContentType } from 'utils/routes';

interface Props {
  handleSubMenuClick: () => void;
  sub: CfReferenceLink[];
};

const MegaMenu: FC<Props> = ({ sub, handleSubMenuClick }) => {

  const [selectCategory, setSelectCategory] = useState<CfReferenceLink>();

  const handleHover = (id: string) => () => {
    setSelectCategory(sub.find(e => e.id === id));
  }

  return (
    <>
      <div
        className={`max-width-100% ${styles.dropdownContainer}`} 
        onMouseLeave={handleSubMenuClick}
      >
        <div className='grid margin-x-xl margin-x-xxxxl@xl'>
          <ul className='col-4 height-auto'>
            {sub.map(category => {
              return (
                <li 
                  onMouseEnter={handleHover(category.id)}
                  onClick={handleSubMenuClick}  
                  onFocus={handleHover(category.id)}
                  className={`padding-sm cursor-default border-right ${selectCategory?.id === category.id && styles.categoryTitle}`} 
                  key={category.id}
                >
                  <Link href={getUrlForContentType(category.slug, category.contentTypeId)}>
                    <a className={styles.subCategory}>
                      {getCategoryName(category.title)}
                    </a>
                  </Link>
                </li>
              )
            })}
          </ul>
            <div className='col-8 grid'>
                {sub.map(subItem => {
                  if (subItem.id !== selectCategory?.id) {
                    return null;
                  }
                  return (
                    <ul key={subItem.id} className='flex flex-wrap flex-column margin-top-sm margin-left-lg'>
                      {subItem.subCategories.map(subList => 
                        <li 
                          onClick={handleSubMenuClick} 
                          className='cursor-pointer padding-bottom-xs'
                          key={subList.id}
                        >
                          <Link href={getUrlForContentType(subList.slug, subList.contentTypeId)}>
                            <a className={styles.subItems}>
                              {getCategoryName(subList.title)}
                            </a>
                          </Link>
                        </li>
                      )}
                    </ul>
                  )
                })}
            </div>
        </div>
      </div>
   </>
  )
}

export default MegaMenu;