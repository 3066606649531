import { FC, MouseEvent } from 'react';
import cn from 'classnames';

import s from './tabs.module.scss';

import { TabNavChild } from './Tabs';

interface Props extends Partial<TabNavChild> {
  id: string;
  label: string;
  className?: string;
  disabled?: boolean;
}

const Tab: FC<Props> = ({
  id,
  label,
  className,
  disabled = false,
  selected = false,
  tabIndex,
  index,
  onClick,
  ...other
}) => {
  const handleOnClick = (event: MouseEvent<HTMLLIElement>) => {
    if (disabled) return;
    if (typeof onClick === 'function') {
      onClick(event, index || 0, tabIndex || 0);
    }
  };

  const classes = cn(className, s.tab, {
    [s.disabled]: disabled,
    [s.selected]: selected,
  });
 
  return (
    <li {...other} tabIndex={-1} className={classes} onClick={handleOnClick} role='presentation'>
      <button
        type='button'
        role='tab'
        aria-selected={selected}
        aria-controls={`${id}__panel`}
        id={id}
        className={cn(s.tabButton, 'reset')}
        tabIndex={tabIndex || 0}
      >
        {label}
      </button>
    </li>
  );
};

export default Tab;
