import { ReactNode, FC } from 'react';
import { CfImage } from 'interfaces/Contentful';
import cn from 'classnames';
import Image from 'next/image';

import s from './hero.module.scss';

interface Props {
  image: CfImage;
  children: ReactNode | ReactNode[];
}

const Hero: FC<Props> = ({ image, children }) => {
  const imageSrc = image.url.startsWith('//') ? `https:${image.url}` : image.url;

  return (
    <section className={s.hero}>
      <div className='grid width-100%'>
        <div className='col-12 col-4@md order-2 order-1@md padding-md padding-y-xl@md padding-x-lg@md'>
          <div className={cn(s.children, 'text-component v-space-lg line-height-lg')}>{children}</div>
        </div>
        <div className={cn(s.image, 'col-12 col-8@md order-1 order-2@md position-relative')}>
          <Image src={imageSrc} layout='fill' objectFit='cover' priority />
        </div>
      </div>
    </section>
  );
};

export default Hero;
