import Link from 'next/link';
import React, { FC, memo } from 'react';
import cn from 'classnames';

import s from './top-header.module.scss';

// Components
import { LoginLink } from 'components/common';

// Utils
import { getUrlForContentType } from 'utils/routes';

// Interfaces
import { CfReferenceLink } from 'interfaces/Contentful';

interface Props {
  text?: string;
  menu?: CfReferenceLink[];
}

const TopHeader: FC<Props> = ({ text, menu }) => {
  if (!text && !menu) {
    return null;
  } else if (!text && Array.isArray(menu) && menu.length === 0) {
    return null;
  }

  const menuItems = menu?.map((link) => {
    const slug = getUrlForContentType(link.slug, link.contentTypeId);
    return (
      <li key={link.id}>
        <Link href={slug} passHref>
          <a title={link.title} className='color-inherit text-decoration-none'>
            {link.title}
          </a>
        </Link>
      </li>
    );
  });

  const innerClass = cn('container', 'max-width-lg', s.inner);

  return (
    <div className={s.container}>
      <div className={innerClass}>
        <p>
          <a
            href='https://informationsteknik.se'
            target='_blank'
            rel='noreferrer'
            className='text-decoration-none color-black'
          >
            Informationsteknik.se
          </a>
        </p>
        <ul className='flex gap-x-md items-center display@md'>
          {menuItems}
          <li>
            <LoginLink />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default memo(TopHeader);
