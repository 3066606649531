import React from 'react';
import cn from 'classnames';

import styles from './shopping-bag.module.scss';

interface Props {
  className?: string;
}

const ShoppingBagIcon: React.FC<Props> = ({ className }) => {
  const classes = cn(styles.icon, 'icon', {
    [`${className}`]: className,
  });

  return (
    <svg className={classes} width='27' height='21' viewBox='0 0 27 21'>
      <g>
        <path strokeLinecap='round' strokeLinejoin='round' d='M0 0h5.037l5.371 16h14.709L29 4.186H11.724' />
        <circle cx='22.5' cy='20.5' r='1.75' />
        <circle cx='13.5' cy='20.5' r='1.75' />
      </g>
    </svg>
  );
};

const ShoppingBag = React.memo(ShoppingBagIcon);

export default ShoppingBag;
