import React from 'react';
import classNames from 'classnames';

interface Props {
  name: string;
  value: string;
  type?: 'email' | 'tel' | 'text' | 'password' | 'hidden';
  label?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  tabIndex?: number;
  disabled?: boolean;
  className?: string;
  inputClassname?: string;
  autoComplete?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Text: React.FC<Props> = ({
  type = 'text',
  label,
  name,
  placeholder,
  required = false,
  disabled = false,
  errorMessage,
  value,
  tabIndex,
  autoComplete,
  className,
  inputClassname,
  onChange,
  onBlur,
  onFocus,
}) => {
  const inputClasses = classNames('form-control', {
    [`${inputClassname}`]: inputClassname,
    'form-control--disabled': disabled,
    'form-control--error': errorMessage,
  });

  return (
    <div className={className}>
      {label && (
        <label className={`form-label margin-bottom-xxs`} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        className={inputClasses}
        type={type}
        name={name}
        id={name}
        required={required}
        value={value}
        placeholder={placeholder}
        tabIndex={tabIndex}
        disabled={disabled}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur && onBlur}
        onFocus={onFocus && onFocus}
      />
      {errorMessage && (
        <p className='text-sm color-error margin-top-xxs' role={'alert'}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Text;
