import { UiState, UiStateWithFunctions } from './interface';

export const initialUiState: UiState = {
  // Display basket
  basketOpen: false,

  // Display mobile navigation
  mobileNavOpen: false,

  // Display authentication modal and current view
  authModalOpen: false,
  authView: 'LOGIN_VIEW',

  // Display market/language selector
  marketLanguageOpen: false,

  // Display search form
  searchOpen: false,

  // Cookie settings
  cookieNoticeOpen: false,
  cookiesSettings: {
    essentials: false,
    analytics: false,
    advertising: false,
    personalisation: false,
  },
};

export const initialUiStateWithFunctions: UiStateWithFunctions = {
  ...initialUiState,

  // Basket
  openBasket: () => {},
  closeBasket: () => {},
  toggleBasket: () => {},

  // Mobile navigation
  openMobileNav: () => {},
  closeMobileNav: () => {},
  toggleMobileNav: () => {},

  // Authentication modal
  openAuthModal: () => {},
  closeAuthModal: () => {},
  setAuthModalView: () => {},

  // Market/Language selector
  openMarketLanguage: () => {},
  closeMarketLanguage: () => {},

  // Search
  openSearch: () => {},
  closeSearch: () => {},
  toggleSearch: () => {},

  // Cookie Notice
  openCookieNotice: () => {},
  closeCookieNotice: () => {},
  toggleCookieNotice: () => {},
  changeCookieSettings: () => {},
};
