import { FC, useEffect, useMemo, useReducer, useCallback } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { AxiosError, AxiosResponse } from 'axios';

// Context
import { login, logout, updateUser } from './actions';
import { AuthContext, authInitialState } from './context';
import { authReducer } from './reducer';
import { UpdateCustomerRequest } from './interface';

// Services
import { loginUser } from 'services/auth';

// Interface
import { User } from 'interfaces/Storm/User';
import { Token } from 'interfaces/Token';

// Config
import { axiosClient } from 'config/auth';

const TOKEN_COOKIE_NAME = 'tokenCookie';

export const AuthProvider: FC = (props) => {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const router = useRouter();

  const signOut = useCallback(() => {
    // Move the user to the start page
    router.push('/');

    // remove any saved user from local storage
    window.localStorage.removeItem('user');
    Cookies.remove(TOKEN_COOKIE_NAME);

    // Update our state
    dispatch(logout());
  }, [router]);

  const isTokenExpired = (token: string): boolean => {
    try {
      const decodedToken = jwtDecode<Token>(token);
      return Date.now() >= decodedToken.exp * 1000;
    } catch {
      console.log('Error decoding token to get expiry date.');
      return true;
    }
  };

  useEffect(() => {
    const savedUser = getCurrentUser();
    if (savedUser) {
      if (isTokenExpired(savedUser.token)) {
        signOut();
      } else {
        dispatch(login(savedUser));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On route change
  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      if (isTokenExpired(currentUser.token)) {
        signOut();
      } else {
        const tokenCookie = Cookies.get(TOKEN_COOKIE_NAME);
        if (!tokenCookie) {
          // If current user exists but no token cookie, set token cookie
          Cookies.set(TOKEN_COOKIE_NAME, currentUser.token, {
            expires: 7,
          });
        }
      }
    } else {
      Cookies.remove(TOKEN_COOKIE_NAME);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const signIn = useCallback(
    async (email: string, password: string, redirect?: boolean): Promise<void> => {
      const response = await loginUser(email, password);
      // Save the user in local storage so it's easy to retreive later
      if (response.data.token) {
        window.localStorage.setItem('user', JSON.stringify(response.data));
        // Set a cookie in order to access token in getServerSideProps/api routes etc.
        Cookies.set(TOKEN_COOKIE_NAME, response.data.token, {
          expires: 7, // expires in 7 days
        });
      }

      // Update our state with the new user
      dispatch(login(response.data));

      // If redirect is enables push the user to the account page
      if (redirect) {
        router.push('/account');
      }
    },
    [router]
  );

  const updateUserToLocalStorage = (user: UpdateCustomerRequest) => {
    dispatch(updateUser(user));
    // keep token in local storage
    const currentUserJson = window.localStorage.getItem('user');
    if (currentUserJson) {
      const currentUser = JSON.parse(currentUserJson);
      const updatedUser: User = {
        ...currentUser,
        ...user,
      };
      window.localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  };

  const getCurrentUser = (): User | null => {
    const savedUser = window.localStorage.getItem('user');
    if (savedUser) {
      return JSON.parse(savedUser);
    }
    return null;
  };

  // Sign out user on 401 responses from API
  axiosClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        signOut();
      } else {
        return Promise.reject(error);
      }
    }
  );

  const value = useMemo(
    () => ({
      ...state,
      signIn,
      signOut,
      getCurrentUser,
      updateUserToLocalStorage,
    }),
    [signIn, signOut, state]
  );

  return <AuthContext.Provider value={value} {...props} />;
};
