import { FC, useRef } from 'react';
import { useRouter } from 'next/router';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import qs from 'qs';

// Hooks
import useUI from 'hooks/useUI';

// Components
import { AlgoliaAutocomplete } from 'components/algolia';

// Config
import { algoliaMainIndex, algoliaSearchClient, modifiedAlgoliaSearchClient } from 'config/algolia/algoliaConfig';

interface Props {
  open?: boolean;
  onClose: () => void;
}

// const createURL = (state) => state ? `?${qs.stringify(state)}` : null;

// const searchStateToUrl = (searchState) => (searchState ? createURL(searchState) : '');

// const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

const SearchForm: FC<Props> = ({ open = false }) => {
  const { closeSearch } = useUI();
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);

  if (!open) {
    return null;
  }

  return (
    <div className='grid bg-sand'>
      <InstantSearch
        searchClient={modifiedAlgoliaSearchClient}
        indexName={algoliaMainIndex}
        routing={false}
        onStateChange={(state) => {
          const href = `?${qs.stringify(state.uiState)}`;
          if (href !== '?') {
            router.push(`/search${href}`);
          }
        }}
      >
        <div className='col padding-y-sm padding-x-md'>
          <div className='max-width-sm margin-auto'>
            <AlgoliaAutocomplete
              suggestionsSearchClient={algoliaSearchClient}
              detachedMediaQuery='none'
              searchAsYouType={false}
              placeholder='Sök på produktnamn eller tillverkare'
            />
          </div>
        </div>
        <div className='col-content padding-right-md bg-sand flex items-center justify-center' ref={ref}>
          <button className='reset cursor-pointer' onClick={closeSearch}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17'>
              <g stroke='#1A1A1A' fill='none' fillRule='evenodd' strokeLinecap='round'>
                <path d='M.393.82l15.213 14.976M15.606.82L.393 15.796' />
              </g>
            </svg>
          </button>
        </div>
      </InstantSearch>
    </div>
  );
};

export default SearchForm;
