import { FC, ChangeEvent } from 'react';
import cn from 'classnames';

import styles from './quantity.module.scss';

interface Props {
  name: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (quantity: number) => void;
}

const Quantity: FC<Props> = ({ name, value, min = 0, max = 10, onChange }) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newValue = !Number.isNaN(value) ? parseInt(event.target.value, 10) : '';
    if (newValue >= max || newValue <= min) {
      return;
    } else if (typeof newValue === 'number' && Number.isNaN(newValue)) {
      // allow non numbers to be inserted such as an empty string but
      // dont call onChange callback
    } else if (typeof newValue === 'number' && !Number.isNaN(newValue)) {
      // if everything is in order then update the state and call the onChange callback
      onChange(newValue);
    }
  };

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const newValue = !Number.isNaN(value) ? parseInt(event.target.value, 10) : '';
    if (newValue >= max || newValue <= min) {
      return;
    } else if (typeof newValue === 'number' && newValue === 0) {
      onChange(1);
    } else if (Number.isNaN(newValue) || Number.isFinite(newValue) || !Number.isSafeInteger(newValue)) {
      onChange(1);
    } else if (typeof newValue === 'string') {
      onChange(1);
    }
  };

  const increment = () => {
    if (typeof value === 'string') {
      return 1;
    }
    const newQuantity = value + 1 <= max ? value + 1 : value;
    onChange(newQuantity);
  };

  const decrement = () => {
    if (typeof value === 'string') {
      return 1;
    }
    const newQuantity = value - 1 > min ? value - 1 : value;
    onChange(newQuantity);
  };

  return (
    <div className={cn(styles.inputWrap, 'flex items-center')}>
      <input
        className={`form-control ${styles.input}`}
        type='number'
        name={name}
        id={name}
        min={min}
        max={max}
        step='1'
        value={Number.isNaN(value) ? '' : value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        tabIndex={2}
      />

      <button
        className={cn(styles.button, styles.buttonPlus)}
        aria-label='Increase Number'
        onClick={increment}
        tabIndex={3}
      >
        <svg className={cn(styles.icon, 'icon')} viewBox='0 0 12 12' aria-hidden='true'>
          <path d='M11,5H7V1A1,1,0,0,0,5,1V5H1A1,1,0,0,0,1,7H5v4a1,1,0,0,0,2,0V7h4a1,1,0,0,0,0-2Z' />
        </svg>
      </button>

      <button
        className={cn(styles.button, styles.buttonMinus, { [styles.buttonMinusDeactivated]: value == 1 })}
        aria-label='Decrease Number'
        onClick={decrement}
        tabIndex={1}
      >
        <svg className={cn(styles.icon, 'icon')} viewBox='0 0 12 12' aria-hidden='true'>
          <path d='M11,7H1A1,1,0,0,1,1,5H11a1,1,0,0,1,0,2Z' />
        </svg>
      </button>
    </div>
  );
};

export default Quantity;
