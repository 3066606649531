import { FC, ChangeEvent, MouseEvent, ReactNode, useRef, useState, Children } from 'react';
import classNames from 'classnames';
import { AnimatePresence, m as motion } from 'framer-motion';

import styles from './choice-accordion.module.scss';

export const ChoiceAccordion: FC = ({ children }) => {
  return <div className={styles.accordionGroup}>{children}</div>;
};

interface ItemProps {
  title: string;
  id: string;
  groupName: string;
  checked: boolean;
  children?: ReactNode | ReactNode[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ChoiceAccordionItem: FC<ItemProps> = ({
  title,
  groupName,
  id,
  checked,
  children,
  onChange,
  onBlur,
  onFocus,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const handleOnFocus = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (inputRef.current) {
      // Since the user isn't actually clicking the input element we need to
      // force it in order to trigger onFocus and onClick events

      // 1. Focus on the input
      inputRef.current.focus();

      // 2. Click on the input (causing onChange to trigger)
      inputRef.current.click();
    }
  };

  const btnClasses = classNames(styles.btn, 'padding-sm', {
    [styles.btnChecked]: checked,
    [styles.btnFocus]: isFocused,
  });

  return (
    <div className={styles.item}>
      {/* <!-- fallback --> */}
      <div className={styles.fallback}>
        <input
          type='radio'
          name={groupName}
          id={`accordion-choice-${id}`}
          value={id}
          checked={checked}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          ref={inputRef}
          aria-expanded={checked}
          aria-controls={`choice-accordion-content-${id}`}
        />
        <label htmlFor={`accordion-choice-${id}`}>{title}</label>
      </div>

      {/* <!-- control --> */}
      <div className={btnClasses} aria-hidden='true' onClick={handleOnClick}>
        <div className={`${styles.input} ${styles.inputRadio}`}>
          <svg className={`${styles.icon} icon`} viewBox='0 0 16 16'>
            <circle fill='currentColor' cx='8' cy='8' r='4' />
          </svg>
        </div>

        <p className={`color-contrast-higher user-select-none`}>{title}</p>
      </div>

      {/* <!-- panel --> */}
      <AnimatePresence initial={checked}>
        {checked && Children.count(children) > 0 && (
          <motion.div
            className={'overflow-hidden'}
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <div
              className={styles.panel}
              aria-labelledby={`accordion-choice-${id}`}
              id={`choice-accordion-content-${id}`}
            >
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
