import { useI18n } from 'hooks/useI18n';
import { Basket } from 'interfaces/Storm/Basket';
import { FC, useReducer, useEffect, useCallback, useMemo } from 'react';
import { getBasket, addToBasket, updateItemInBasket, removeItemFromBasket } from 'services/storm';
import { measureAdditionToBasket, measureRemovalFromBasket } from 'utils/tracking';
import { ActionTypes } from './actions';
import BasketContext from './context';
import initialState from './initialState';
import basketReducer from './reducer';

const BasketProvider: FC = (props) => {
  const [state, dispatch] = useReducer(basketReducer, initialState);
  const { currency } = useI18n();

  useEffect(() => {
    async function fetchInitialBasket() {
      const response = await getBasket();
      dispatch({ type: ActionTypes.UpdateBasket, payload: { basket: response.data } });
    }

    if (state.externalId === '') {
      fetchInitialBasket();
    }
  }, [state.externalId]);

  async function addProduct(partNumber: string, quantity: number) {
    try {
      const response = await addToBasket(partNumber, quantity);
      if (response.data) {
        // Update state
        dispatch({ type: ActionTypes.UpdateBasket, payload: { basket: response.data } });

        // verify that we have the new item in the basket and if so send analytics event
        const updatedItem = response.data.items?.find((item) => item.partNo === partNumber);
        if (updatedItem) {
          measureAdditionToBasket(updatedItem, currency);
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function updateProduct(partNumber: string, quantity: number) {
    try {
      const response = await updateItemInBasket(partNumber, quantity);
      if (response.data) {
        dispatch({ type: ActionTypes.UpdateBasket, payload: { basket: response.data } });
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function removeProduct(partNumber: string) {
    try {
      const response = await removeItemFromBasket(partNumber);
      const oldProduct = state.items?.find((p) => p.partNo === partNumber);
      if (oldProduct) {
        measureRemovalFromBasket(oldProduct, currency);
      }
      if (response.data) {
        dispatch({ type: ActionTypes.UpdateBasket, payload: { basket: response.data } });
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function emptyBasket() {
    dispatch({ type: ActionTypes.EmptyBasket });
  }

  async function updateBasket(basket: Basket) {
    try {
      if (basket) {
        dispatch({ type: ActionTypes.UpdateBasket, payload: { basket }});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getTotalQuantity = useCallback(() => {
    let total = 0;
    if (!state.items) {
      return total;
    }
    total = state.items.reduce((total, item) => {
      return total + item.quantity;
    }, total);
    return total;
  }, [state.items]);

  const getTotalAmount = useCallback(() => {
    return state.totalInclVat;
  }, [state.totalInclVat]);

  const value = useMemo(
    () => ({
      ...state,
      addProduct,
      updateProduct,
      removeProduct,
      getTotalQuantity,
      getTotalAmount,
      updateBasket,
      emptyBasket
    }),
    [getTotalAmount, getTotalQuantity, state]
  );

  return <BasketContext.Provider value={value} {...props} />;
};

export default BasketProvider;
