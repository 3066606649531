import { FC, Children, cloneElement, ReactElement } from 'react';
import { useBreadcrumbs } from '@react-aria/breadcrumbs';
import { useIntl } from 'react-intl';

import s from './breadcrumbs.module.scss';

import { BreadcrumbItemProps } from './BreadcrumbItem';

interface Props {
  children: ReactElement<BreadcrumbItemProps> | ReactElement<BreadcrumbItemProps>[];
}

const Breadcrumbs: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { navProps } = useBreadcrumbs(props);
  const children = Children.toArray(props.children) as ReactElement<BreadcrumbItemProps>[];

  return (
    <nav
      {...navProps}
      className={s.breadcrumbs}
      aria-label={formatMessage({ id: 'breadcrumbs.title', defaultMessage: 'Breadcrumbs' })}
    >
      <ol className='flex flex-wrap'>
        {children.map((child, i) => cloneElement(child, { isCurrent: i === children.length - 1 }))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
