import s from './LoadingDots.module.scss';

const LoadingDots: React.FC = () => {
  return (
    <span className={s.root}>
      <span key='loading-dot-1' />
      <span key='loading-dot-2' />
      <span key='loading-dot-3' />
    </span>
  );
};

export default LoadingDots;
