import { FC, ReactNode, useRef } from 'react';
import { useBreadcrumbItem } from '@react-aria/breadcrumbs';
import Link from 'next/link';
import cn from 'classnames';

import s from './breadcrumbs.module.scss';

export interface BreadcrumbItemProps {
  href: string;
  isCurrent?: boolean;
  children: ReactNode;
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = (props) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { itemProps } = useBreadcrumbItem(props, ref);

  if (props.isCurrent) {
    return (
      <li className={cn(s.breadcrumb, s.current)}>
        <Link href={props.href} passHref>
          <a {...itemProps} ref={ref}>
            {props.children}
          </a>
        </Link>
      </li>
    );
  }

  return (
    <li className={`margin-y-sm ${s.breadcrumb}`}>
      <Link href={props.href} passHref>
        <a className='color-contrast-medium' {...itemProps} ref={ref}>
          {props.children}
        </a>
      </Link>
      <div className='margin-x-md'>
        /
      </div>
        
    </li>
  );
};

export default BreadcrumbItem;
