import { Basket } from 'interfaces/Storm/Basket';
import { ActionTypes, Action } from './actions';
import initialState from './initialState';

function basketReducer(state: Basket, action: Action) {
  switch (action.type) {
    case ActionTypes.UpdateBasket: {
      return {
        ...state,
        ...action.payload.basket,
      };
    }
    case ActionTypes.EmptyBasket:{
      return initialState;
    }

    default:
      return state;
  }
}

export default basketReducer;
