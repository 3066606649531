import { FC } from 'react';
import cn from 'classnames';

import { ExclamationMark, Success, Info, Cross } from 'components/icons';

import s from './alert.module.scss';

interface Props {
  type: 'success' | 'warning' | 'alert' | 'info';
  onClose: () => void;
  className?: string;
}

const Alert: FC<Props> = ({ type, children, onClose, className }) => {
  const classes = cn(s.alert, {
    [s.success]: type === 'success',
    [s.info]: type === 'info',
    [s.warning]: type === 'warning',
    [s.error]: type === 'alert',
    [`${className}`]: className,
  });

  return (
    <div className={classes} role='alert'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          {type === 'info' && <Info className={'icon margin-right-xs'} />}
          {type === 'warning' && <ExclamationMark className={'icon margin-right-xs'} />}
          {type === 'alert' && <ExclamationMark className={'icon margin-right-xs'} />}
          {type === 'success' && <Success className={'icon margin-right-xs'} />}
          <p>{children}</p>
        </div>
        <button className={s.button} onClick={onClose}>
          <Cross className={cn(s.icon, 'icon')} />
        </button>
      </div>
    </div>
  );
};

export default Alert;
