import { FC } from 'react';
import { IconProps } from './IconProps';

const Cross: FC<IconProps> = ({ ...props }) => {
  return (
    <svg className='icon' viewBox='0 0 20 20' {...props}>
      {props.title && <title>{props.title}</title>}
      <g fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='2'>
        <line x1='3' y1='3' x2='17' y2='17' />
        <line x1='17' y1='3' x2='3' y2='17' />
      </g>
    </svg>
  );
};

export default Cross;
