import React from 'react';
import classNames from 'classnames';

import styles from './select.module.scss';

type OptionProps = React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>;
type OptGroupProps = React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptGroupElement>, HTMLOptGroupElement>;

interface Props {
  label?: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  className?: string;
  autoComplete?: string;
  children:
    | React.ReactElement<OptionProps>
    | React.ReactElement<OptGroupProps>
    | React.ReactElement<OptionProps>[]
    | React.ReactElement<OptGroupProps>[];
}

const Select: React.FC<Props> = ({
  label,
  name,
  value,
  className,
  children,
  autoComplete,
  onChange,
  onBlur,
  onFocus,
}) => {
  const handleOnBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLSelectElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const classes = classNames({
    [`${className}`]: className,
  });

  return (
    <div className={classes}>
      {label && (
        <label className={`form-label margin-bottom-xxs`} htmlFor={name}>
          {label}
        </label>
      )}

      <div className={styles.select}>
        <select
          className={`${styles.input} form-control`}
          name={name}
          id={name}
          value={value}
          autoComplete={autoComplete}
          onChange={onChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        >
          {children}
        </select>

        <svg className={`icon ${styles.icon}`} aria-hidden='true' viewBox='0 0 16 16'>
          <g strokeWidth='1' stroke='currentColor'>
            <polyline
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              points='15.5,4.5 8,12 0.5,4.5 '
            ></polyline>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Select;
