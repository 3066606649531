import { FC } from 'react';

interface Props {
  className?: string;
  html: string;
}

const HTMLContent: FC<Props> = ({ className, html }) => {
  return <div className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default HTMLContent;
