import { AuthActions, ActionTypes } from './actions';
import { AuthState } from './interface';

export function authReducer(state: AuthState, action: AuthActions): AuthState {
  switch (action.type) {
    case ActionTypes.login: {
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
      };
    }
    case ActionTypes.logout: {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    }
    case ActionTypes.updateUser: {
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    }
    default:
      return state;
  }
}
