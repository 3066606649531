import { FC } from 'react';
import { IconProps } from './IconProps';

const CheckMark: FC<IconProps> = (props) => {
  return (
    <svg {...props} viewBox='0 0 80 80' aria-hidden='true'>
      <g>
        <circle fill='var(--color-success)' cx='40' cy='40' r='40' />
        <polyline points='21 41 33 53 59 27' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='2' />
      </g>
    </svg>
  );
};

CheckMark.defaultProps = {
  className: 'icon',
};

export default CheckMark;
