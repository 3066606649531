import React, { useRef } from 'react';
import classNames from 'classnames';

import styles from './choice-button.module.scss';

interface Props {
  // The label for the actual input element and screen readers
  label: string;
  name: string;
  checked: boolean;
  value: string;
  // The label for the buttons
  children: React.ReactNode | React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChoiceButton: React.FC<Props> = ({ label, value, name, checked, children, onChange, onBlur, onFocus }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const handleOnFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (inputRef.current) {
      // Since the user isn't actually clicking the input element we need to
      // force it in order to trigger onFocus and onClick events

      // 1. Focus on the input
      inputRef.current.focus();

      // 2. Click on the input (causing onChange to trigger)
      inputRef.current.click();
    }
  };

  const classes = classNames(styles.choiceBtn, 'padding-sm', {
    [styles.checked]: checked,
  });

  return (
    <div className={classes} onClick={handleOnClick}>
      {/* <!-- fallback --> */}
      <div className={styles.fallback}>
        <input
          type='radio'
          name={name}
          id={value}
          value={value}
          checked={checked}
          ref={inputRef}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
        <label htmlFor={value}>{label}</label>
      </div>

      {/* <!-- custom input --> */}
      <div className={styles.grid} aria-hidden='true'>
        <div className={`${styles.input} ${styles.inputRadio}`}>
          <svg className={`${styles.icon} icon`} viewBox='0 0 16 16'>
            <circle fill='currentColor' cx='8' cy='8' r='4' />
          </svg>
        </div>

        <div className={`flex flex-wrap gap-xxs justify-between items-center`}>{children}</div>
      </div>
    </div>
  );
};

export default ChoiceButton;
