import { BasketState } from './interface';

const initialState: BasketState = {
  externalId: '',
  total: 0,
  totalVat: 0,
  totalInclVat: 0,
  shipping: 0,
  shippingInclVat: 0,
  items: [],
  numberOfItems: 0,
  addProduct: () => {},
  updateProduct: () => {},
  removeProduct: () => {},
  getTotalQuantity: () => 0,
  getTotalAmount: () => 0,
  updateBasket: () => {},
  emptyBasket: () => {}
};

export default initialState;
