import React, { FC, MutableRefObject } from 'react';
import {
  useKeenSlider,
  KeenSliderPlugin,
  KeenSliderInstance,
} from "keen-slider/react"
import Image from 'next/image';

// Styles
import 'keen-slider/keen-slider.min.css';
import s from './ProductSlider.module.scss';

// Interfaces
import { ProductDetail } from 'interfaces/Storm/ProductDetail';

interface Props {
  product: ProductDetail;
}

function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active")
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active")
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on("created", () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on("animationStarted", (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(next)
      })
    })
  }
}

const ProductSlider: FC<Props> = ({ product }) => {

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
  })
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 10,
      },
      vertical: true,
    },
    [ThumbnailPlugin(instanceRef)]
  )

  const url = new URL(product.primaryImageUrl);
  
  const mainProductImage = url.pathname !== '/' && {
    src: product.primaryImageUrl,
    alt: product.name,
    id: product.id
  }
  const imageArray = product.images?.map(a => {
    return {
      src: a.imageUrl,
      alt: a.name,
      id: a.externalId
    }
  } );

  if (mainProductImage) {
    imageArray.unshift(
      mainProductImage
    );
  }

  return (
    <div className={s.sliderCon}>
      <div ref={sliderRef} className="keen-slider items-center">
        {imageArray.map((image, index) => {
          return (
            <div key={image.id} className={`keen-slider__slide number-slide${index} items-center flex ${s.productImageWrapper}`}>
              <Image
                key={image.id}
                src={`${image.src}`}
                alt={image.alt || 'Product Image'}
                 width={580}
                 height={580}
                quality={90}
                objectFit='contain'
              />
            </div> 
          ) 
        })} 
      </div>

      <div ref={thumbnailRef} className={`keen-slider thumbnail ${s.verticleSlider}`} style={{width: '200px'}}>
        {imageArray.map((image, index) => {
          return (
            <div key={image.id} className={`keen-slider__slide number-slide${index} ${s.imageBox}`}>
              <Image
                key={image.id}
                src={`${image.src}`}
                alt={image.alt || 'Product Image'}
                width={600}
                height={600}
                quality={90}
                objectFit='contain'
              />
            </div>
          )
        })} 
      </div>
    </div>
  );
};

export default ProductSlider;
