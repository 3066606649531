import { FC, useRef, useEffect, useCallback } from 'react';
import Portal from '@reach/portal';
import cn from 'classnames';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import s from './sidebar.module.scss';

interface Props {
  className?: string;
  open?: boolean;
  onClose: () => void;
  position?: 'left' | 'right';
}

const Sidebar: FC<Props> = ({ children, open = false, onClose, className, position = 'right' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current);
        window.addEventListener('keydown', handleKey);
      } else {
        enableBodyScroll(ref.current);
      }
    }
    return () => {
      window.removeEventListener('keydown', handleKey);
      clearAllBodyScrollLocks();
    };
  }, [open, handleKey]);

  return (
    <Portal>
      {open ? (
        <div className={cn(s.root, className)} ref={ref}>
          <div className={s.inner}>
            <div className={s.backdrop} onClick={() => onClose()} />
            <section className={cn(s.drawerWrap, { 'left-0': position === 'left', 'right-0': position === 'right' } )}>
              <div className={s.drawer}>
                <div className={s.drawerInner}>{children}</div>
              </div>
            </section>
          </div>
        </div>
      ) : null}
    </Portal>
  );
};

export default Sidebar;
