import { FC } from 'react';
import { IconProps } from './IconProps';

const ExclamationMark: FC<IconProps> = (props) => {
  return (
    <svg aria-hidden='true' viewBox='0 0 32 32' {...props}>
      {props.title && <title>{props.title}</title>}
      <g>
        <path d='M16,0C7.178,0,0,7.178,0,16s7.178,16,16,16s16-7.178,16-16S24.822,0,16,0z M17.929,7L17,19h-2L14.071,7 H17.929z M16,25c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2C18,24.105,17.105,25,16,25z'></path>
      </g>
    </svg>
  );
};

ExclamationMark.defaultProps = {
  className: 'icon',
};

export default ExclamationMark;
