import cn from 'classnames';
import { FC } from 'react';

import s from './Featurebar.module.scss';

interface Props {
  className?: string;
  hide?: boolean;
  action?: React.ReactNode;
}

const FeatureBar: FC<Props> = ({ children, className, action, hide }) => {
  const classes = cn(s.notice, className, {
    [s.hide]: hide,
  });

  return (
    <div className={classes}>
      <div className='container'>
        <div className={s.banner}>
          <div className='text-component text-sm margin-bottom-md'>{children}</div>
          {action && <div>{action}</div>}
        </div>
      </div>
    </div>
  );
};

export default FeatureBar;
