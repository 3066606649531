import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { format } from 'date-fns';

// Interfaces
import { GetCategoryResponse } from 'interfaces/Storm/Responses/CategoryResponse';
import {
  GetProductsResponse,
  GetProductByUniqueNameResponse,
  GetPaginatedProductsResponse,
} from 'interfaces/Storm/Responses/ProductResponses';
import { GetCheckoutResponse } from 'interfaces/Storm/Responses/ShoppingResponses';
import { GetProductSearchResponse } from 'interfaces/Storm/Responses/SearchResponse';
import { Basket } from 'interfaces/Storm/Basket';
import { Buyer, BuyerShipTo, Checkout } from 'interfaces/Storm/Checkout';
import { InvoiceList } from 'interfaces/Storm/Invoice';
import { Order } from 'interfaces/Storm/Order';
import { OrderListItem } from 'interfaces/Storm/OrderListItem';
import { ProductRelations } from 'interfaces/Storm/ProductDetail';
import { axiosClient } from 'config/auth';

// Product endpoints
export const getProducts = () => axiosClient.get<GetProductsResponse>(`/products`);
export const getProductByUniqueName = (uniqueName: string, config?: AxiosRequestConfig) =>
  axiosClient.get<GetProductByUniqueNameResponse>(`/products/name/${uniqueName}`, config);
export const getProductByPartNo = (partNo: string, config?: AxiosRequestConfig) =>
  axiosClient.get<GetProductByUniqueNameResponse>(`/products/partno/${partNo}`, config);
export const getProductsByCategorySeed = (categorySeed: string, config?: AxiosRequestConfig) =>
  axiosClient.get<GetProductsResponse>(`/products/${categorySeed}`, config);
export const getPopularProducts = (config?: AxiosRequestConfig) => axiosClient.get<GetProductsResponse>('/products/popular', config);
export const getBestSellingProducts = (config?: AxiosRequestConfig) => axiosClient.get<GetProductsResponse>('/products/bestselling', config);
export const searchProducts = (query: string, config?: AxiosRequestConfig) =>
  axiosClient.get<GetProductSearchResponse>(`/products/search?query=${query}`, config);
export const getProductsByRelations = (productId: string, config?: AxiosRequestConfig) =>
  axiosClient.get<ProductRelations[]>(`/products/relations/${productId}`, config);
export const getProductsByPartNos = (partNos: string, config?: AxiosRequestConfig): Promise<AxiosResponse<GetProductsResponse>> => {
  return axiosClient.post<GetProductsResponse>(`/products/partnos`, { partNos }, config)
}

export const getProductsByCustomer = (
  pageNo?: number,
  pageSize?: number,
): Promise<AxiosResponse<GetPaginatedProductsResponse>> => {
  const config = {
    params: {
      pageNo,
      pageSize,
    },
  };
  return axiosClient.get<GetPaginatedProductsResponse>('/products/customer', config);
};

export const getProductsByCompany = (
  pageNo?: number,
  pageSize?: number
): Promise<AxiosResponse<GetPaginatedProductsResponse>> => {
  const config = {
    params: {
      pageNo,
      pageSize,
    },
  };
  return axiosClient.get<GetPaginatedProductsResponse>('/products/company', config);
};

// Category endpoints
export const getProductsAndFiltersByCategorySeed = (categorySeed: number | number[], params: Record<string, any>, config?: AxiosRequestConfig) => {
  return axiosClient.get<GetCategoryResponse>(`/categories/${categorySeed}`, {
    ...config,
    params
  })
};

export const getProductsAndFiltersByCategorySeedV2 = (categorySeed: number | number[], params: Record<string, any>, config?: AxiosRequestConfig) => {
  return axiosClient.get<GetCategoryResponse>(`/categories/v2/${categorySeed}`, {
    ...config,
    params
  })
};

// Analytics endpoint
export const updateProductViewCount = (productId: string, categoryId: string) =>
  axiosClient.post(`analytics/productviewcount`, null, {
    params: {
      productId,
      categoryId,
    },
  });

// Basket endpoints
export const getBasket = () => axiosClient.get<Basket>(`/basket`);
export const addToBasket = (partNumber: string, quantity = 1) =>
  axiosClient.post<Basket>(`/basket`, null, {
    params: {
      quantity,
      partNo: partNumber
    },
  });

export const updateItemInBasket = (partNumber: string, quantity: number) =>
  axiosClient.patch<Basket>(`/basket`, null, {
    params: {
      quantity,
      partNo: partNumber
    },
  });

export const removeItemFromBasket = (partNumber: string) => axiosClient.delete<Basket>(`/basket`, {
  params: {
    partNo: partNumber
  }
});

// Shopping endpoints
export const getCheckout = (): Promise<AxiosResponse<GetCheckoutResponse>> => {
  return axiosClient.get<GetCheckoutResponse>(`/checkout`);
};

export const changeDeliveryMethod = (methodId: string): Promise<AxiosResponse<Checkout>> => {
  return axiosClient.post<Checkout>(`/checkout/deliverymethod`, {}, { params: { deliveryMethodId: methodId } });
};

export const changePaymentMethod = (methodId: string): Promise<AxiosResponse<Checkout>> => {
  return axiosClient.post<Checkout>(`/checkout/paymentmethod`, {}, { params: { paymentMethod: methodId } });
};

export const getOrderConfirmation = (id: string): Promise<AxiosResponse<Checkout>> => {
  return axiosClient.get<Checkout>(`/checkout/orderconfirmation`, { params: { basketId: id } });
};

export const updateComment = (comment: string): Promise<AxiosResponse<Basket>> => {
  return axiosClient.post<Basket>(`/checkout/comment`, {}, { params: { comment: comment } });
};

export const submitCheckout = (): Promise<AxiosResponse<string>> => {
  return axiosClient.post<string>(`/checkout/submitCheckout`, {});
};

export const updateBuyer = (customer: BuyerShipTo): Promise<AxiosResponse<Checkout>> => {
  return axiosClient.post<Checkout>(`/checkout/updatebuyer`, { customer });
};

export const updateShipTo = (customer: Buyer): Promise<AxiosResponse<Checkout>> => {
  return axiosClient.post<Checkout>(`/checkout/updateshipto`, { customer: customer });
};

// Order endpoints
export const getOrders = (): Promise<AxiosResponse<OrderListItem[]>> => {
  return axiosClient.get<OrderListItem[]>(`/orders/customer?toDate=${format(new Date(), 'yyyy-MM-dd')}&fromDate=2020-01-01`);
};

export const getOrder = (orderId: string): Promise<AxiosResponse<Order>> => {
  return axiosClient.get<Order>(`/orders/${orderId}`);
};

export const getInvoice = (orderId: string): Promise<AxiosResponse<InvoiceList>> => {
  return axiosClient.get<InvoiceList>(`/orders/invoices/`, { params: { orderId: orderId } });
};

export const copyOrder = (orderId: number): Promise<AxiosResponse<Basket>> => {
  return axiosClient.post<Basket>(`/orders/copy/`, {}, { params: { orderId: orderId } });
};


