import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosClient } from 'config/auth';

import { User } from 'interfaces/Storm/User';

export const loginUser = (username: string, password: string) => {
  return axiosClient.post<User>('/user/login', { username, password });
};

export interface RegisterUser {
  email: string;
  ssn: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

export const registerUser = (user: RegisterUser) => {
  return axiosClient.post<User>('/user/register', user);
};

export const getUserByEmail = (email: string) => {
  const config: AxiosRequestConfig = {
    params: { email },
  };
  return axiosClient.get<User>('/user/email', config);
};

interface UpdateCustomerRequest {
  firstName: string;
  lastName: string;
  phone: string;
  cellPhone: string;
  email: string;
}

export const updateAccountSettings = (data: UpdateCustomerRequest) => {
  return axiosClient.post<User>('/user/update', data);
};

interface ChangeUserModel {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export const changeUserPassword = (data: ChangeUserModel) => {
  return axiosClient.post<User>('/user/password/change', data);
};

interface ResetUserPassword {
  email: string;
  accountKey: string;
  newPassword: string;
}

export const resetUserPassword = (data: ResetUserPassword) => {
  return axiosClient.post<User>('/user/password/reset', {
    email: data.email,
    accountKey: data.accountKey,
    newPassword: data.newPassword,
  });
};

export const resetPassword = (loginName: string): Promise<AxiosResponse<void>> => {
  return axiosClient.post<void>(`/user/password/reset/sendmail/${loginName}`);
};
