// Framework
import { FC, memo } from 'react';
import Link from 'next/link';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Image from 'next/image';

// Styles
import styles from './promoted-product.module.scss';

// Hooks
import { useI18n } from 'hooks/useI18n';
import useBasket from 'hooks/useBasket';
import useAuth from 'hooks/useAuth';

// Interfaces
import { ProductDetail } from 'interfaces/Storm/ProductDetail';

interface Props {
  product: ProductDetail;
  useBackgroundColor: boolean;
  containerWidth: string;
}

const PromotedProductComponent: FC<Props> = ({ product, containerWidth, useBackgroundColor }) => {
  const { currency } = useI18n();
  const { addProduct } = useBasket();
  const { isLoggedIn } = useAuth();

  const handleOnAddToCart = () => {
    addProduct(product.partNo, 1);
  };

  return (
    <section className='padding-y-xl'>
      <div className={`container ${containerWidth}`}>
        <div className={cn(styles.promotedProduct, 'grid')}>
          {product?.primaryImageUrl && (
            <div className={cn(styles.promotedProductImage, 'col-12 col-6@sm position-relative')}>
              <Image src={product.primaryImageUrl} quality='85' objectFit='cover' layout='fill' alt={product.name} />
            </div>
          )}
          <div
            className={cn('col-12 col-6@sm text-component padding-lg', {
              'bg-sand': useBackgroundColor,
            })}
          >
            <h2>{product.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
            {isLoggedIn && (
              <div className='margin-top-md'>
                <span className={styles.price}>
                  <FormattedNumber value={product?.price} style='currency' currency={currency} />
                </span>
              </div>
            )}
            <div className='flex flex-wrap gap-sm margin-top-md'>
              {isLoggedIn && (
                <button className='btn btn--primary btn--md' onClick={handleOnAddToCart}>
                  <FormattedMessage id='product-view.addToCartButton' defaultMessage='Add to cart' />
                </button>
              )}
              <Link href={`/product/${product.uniqueName}`} passHref>
                <a className='btn btn--subtle btn--md'>
                  <FormattedMessage id='globals.readMore' defaultMessage='Read more' />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PromotedProduct = memo(PromotedProductComponent);
PromotedProduct.displayName = 'PromotedProduct';

export default PromotedProduct;
