import React from 'react';

// Styling
import s from './checkbox-list.module.scss';

// Components
import { Checkbox } from 'components/inputs';

// Utils
import { removeValueFromArray } from 'utils/arrays';

interface Item {
  id: string;
  title: string;
  suffix?: string;
}

type CheckedItem = Item['id'];

interface Props {
  id: string;
  items: Item[];
  active?: CheckedItem[];
  onChange: (id: string, checkedItems: CheckedItem[]) => void;
}

const CheckboxList: React.FC<Props> = ({ id, items, active = [], onChange }) => {
  const handleOnCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const updatedCheckedItems = active.includes(name) ? removeValueFromArray<string>(active, name) : [...active, name];
    onChange(id, updatedCheckedItems);
  };

  return (
    <div className={s.list}>
      {items.map((item) => {
        const isChecked = active.includes(item.id);
        return (
          <div key={item.id} className={s.item}>
            <Checkbox
              name={item.id}
              label={item.title}
              labelSuffix={item.suffix}
              checked={isChecked}
              onChange={handleOnCheckboxChange}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxList;
