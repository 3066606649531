import { FC, ChangeEvent } from 'react';

import styles from './checkbox.module.scss';

interface Props {
  name: string;
  label: string;
  labelSuffix?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<Props> = ({ name, label, labelSuffix, checked, disabled, onChange, onBlur }) => {
  return (
    <div className={styles.container}>
      <input
        className={styles.checkbox}
        type='checkbox'
        id={name}
        name={name}
        onChange={onChange}
        checked={checked}
        onBlur={onBlur}
        disabled={disabled}
      />
      <label htmlFor={name}>
        <span className={styles.label}>{label}</span>
        {labelSuffix && <em className={styles.suffix}>{labelSuffix}</em>}
      </label>
    </div>
  );
};

export default Checkbox;
