import { FC, ChangeEvent, useEffect, useState } from 'react';
import qs from 'query-string';
import { useRouter, NextRouter } from 'next/router';
import { useIntl } from 'react-intl';

// Hooks
import { usePrevious } from 'hooks/usePrevious';

// Utils
import { removeFromLast } from 'utils/routes';

// Styling
import styles from './product-sorting.module.scss';

const getQueryParams = (router: NextRouter) => qs.parseUrl(router.asPath).query;

const DEFAULT_SORTING = 'title';

const ProductSorting: FC = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const previousRoute = usePrevious(router.asPath);
  const [sorting, setSorting] = useState(() => {
    const searchParams = qs.parseUrl(router.asPath);
    if (searchParams.query?.sortBy) {
      return searchParams.query?.sortBy;
    }
    return DEFAULT_SORTING;
  });

  // If we have navigated to a new path then reset the sorting state to the default
  useEffect(() => {
    const currentPath = removeFromLast(router.asPath, '?');
    const previousPath = previousRoute && removeFromLast(previousRoute, '?');
    if (previousPath && currentPath !== previousPath) {
      setSorting(DEFAULT_SORTING);
    }
  }, [router.asPath, previousRoute]);

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSorting(value);
    updateSearchParams(value);
  };

  const updateSearchParams = (sorting: string) => {
    const sortByParams = {
      sortBy: sorting,
    };
    const originalParams = getQueryParams(router);

    const newParams = { ...originalParams, ...sortByParams };

    const searchString = qs.stringify(newParams, { skipNull: true, arrayFormat: 'comma' });
    const slug = Array.isArray(router.query.slug) ? router.query.slug.join('/') : router.query.slug;
    const asPath = `/category/${slug}${searchString.length > 0 ? '?' + searchString : ''}`;

    if (asPath !== router.asPath) {
      router.push({ pathname: router.pathname, query: searchString }, asPath);
    }
  };

  return (
    <div className='inline-flex items-baseline text-sm'>
      <label className='color-contrast-medium margin-right-xxs' htmlFor='productSorting'>
        {formatMessage({
          id: 'product-sorting.title',
          defaultMessage: 'Sort:',
          description: 'Label for sorting of product list',
        })}
      </label>

      <div className={`${styles.select} inline-block`}>
        <select
          name='productSorting'
          id='productSorting'
          value={sorting}
          onChange={handleOnChange}
          className={styles.input}
        >
          <option value='relevance'>
            {formatMessage({
              id: 'product-sorting.relevance',
              defaultMessage: 'Relevance',
              description: 'Sort products by relevance',
            })}
          </option>
          <option value='name'>
            {formatMessage({
              id: 'product-sorting.byName',
              defaultMessage: 'Name',
              description: 'Sort products by name',
            })}
          </option>
          <option value='price-asc'>
            {formatMessage({
              id: 'product-sorting.byPriceAscending',
              defaultMessage: 'Price (Low to High)',
              description: 'Sort products by price (low to high)',
            })}
          </option>
          <option value='price-desc'>
            {formatMessage({
              id: 'product-sorting.byPriceDescending',
              defaultMessage: 'Price (High to Low)',
              description: 'Sort products by price (High to Low)',
            })}
          </option>
        </select>

        <svg className={`${styles.icon} icon icon--xs margin-left-xxxs`} aria-hidden='true' viewBox='0 0 16 16'>
          <polygon points='3,5 8,11 13,5 '></polygon>
        </svg>
      </div>
    </div>
  );
};

export default ProductSorting;
