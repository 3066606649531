import {
  FC,
  useState,
  Fragment,
  Children,
  cloneElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  isValidElement,
} from 'react';
import cn from 'classnames';

import TabContent from './TabContent';

import s from './tabs.module.scss';

export interface TabNavChild {
  id: string;
  index: number;
  selected: boolean;
  onClick: (event: MouseEvent<HTMLLIElement>, index: number, tabIndex: number) => void;
  tabIndex: number;
  children: ReactNode;
}

interface Props {
  className?: string;
  role?: string;
  selected?: number;
  iconDescription?: string;
  tabContentClassName?: string;
  children?: ReactElement<TabNavChild>[];
}

const Tabs: FC<Props> = ({ selected: defaultSelected, role, className, children, tabContentClassName }) => {
  const [selected, setSelected] = useState<number>(defaultSelected || 0);

  const handleOnTabClick = (_event: MouseEvent<HTMLLIElement>, index: number) => {
    setSelected(index);
  };

  const tabsWithProps = Children.map(children, (tab, index) => {
    const tabPanelIndex = index === selected ? 0 : -1;
    if (!isValidElement(tab)) {
      return null;
    }

    // If the individual tab is missing content then we don't want to render it e.g. an empty tab "<Tab></Tab>"
    if (!tab.props.children) {
      return null;
    }

    const newTab = cloneElement(tab, {
      index,
      selected: index === selected,
      onClick: handleOnTabClick,
      tabIndex: tabPanelIndex,
    });

    return newTab;
  });

  const tabContentWithProps = Children.map(tabsWithProps, (tab) => {
    // Check if there is an actual child and if that element is a valid react element
    if (!tab || !isValidElement(tab)) {
      return null;
    }

    const { children, selected, id } = tab.props;

    return (
      <TabContent
        aria-hidden={!selected}
        selected={selected}
        id={`${id}__panel`}
        aria-labelledby={id}
        innerClassnames={tabContentClassName}
      >
        {children}
      </TabContent>
    );
  });

  return (
    <Fragment>
      <div className={cn(s.root, className)} role={role}>
        <ul role='tablist' className={s.list}>
          {tabsWithProps}
        </ul>
      </div>
      {tabContentWithProps}
    </Fragment>
  );
};

Tabs.defaultProps = {
  role: 'navigation',
  selected: 0,
};

export default Tabs;
