import { FC } from 'react';
import Link from 'next/link';
import { Document } from '@contentful/rich-text-types';
import cn from 'classnames';
import Image from 'next/image';

// Components
import { Logotype } from 'components/common';

// Styles
import styles from './footer.module.scss';

// Interfaces
import { CfReferenceLink } from 'interfaces/Contentful';

// Utils
import { getUrlForContentType } from 'utils/routes';

interface Props {
  tag?: Document;
  mainNavigation: CfReferenceLink[];
}

const Footer: FC<Props> = ({ mainNavigation }) => {
  const footerLinks = mainNavigation?.map((navItem) => {
    const slug = getUrlForContentType(navItem.slug, navItem.contentTypeId);
    return (
      <li className='margin-y-md' key={navItem.id}>
        <Link href={slug} passHref>
          <a className={cn(styles.link)}>{navItem.title}</a>
        </Link>
      </li>
    );
  });

  return (
    <footer className={cn(styles.footer, 'main-footer bg-sand position-relative z-index-1 padding-top-xl')}>
      <div className='container max-width-lg'>
        <div className='gap-lg grid'>
          <section className='col-3@lg order-1@lg text-right@lg'>
            <div className={`margin-right-xxl ${styles.logo}`}>
              <Logotype />
            </div>
          </section>
          <div className='col-9@lg order-1@lg'>
            <ul className='grid gap-lg line-height-body'>
              <li className='col-12 col-6@xs col@md'>
                <section className='margin-y-sm margin-right-xxl@md'>
                  <h3 className='margin-bottom-md'>Produkter</h3>
                  <nav>
                    <ul>{footerLinks}</ul>
                  </nav>
                </section>
              </li>
              <li className='col-12 col-6@xs col@md'>
                <section className='margin-y-sm margin-right-xxl@md'>
                  <h3 className='margin-bottom-md'>Adress</h3>
                  <div>
                    <strong>Informationsteknik Scandinavia AB</strong>
                    <p>Västbergavägen 25</p>
                    <p>126 30 Hägersten</p>
                    <p>Scandinavia AB</p>
                    <p>Box 42101, 126 14 Stockholm</p>
                  </div>
                  <div className='margin-top-sm margin-bottom-md'>
                    <p>
                      <a className='color-accent' href='tel:+46771445400'>
                        +46 771 445 400
                      </a>
                    </p>
                    <p>
                      <a className='color-accent' href='mailto:webshop@informationsteknik.se'>
                        webshop@informationsteknik.se
                      </a>
                    </p>
                  </div>
                  <div className='flex gap-sm padding-top-xs'>
                    <a
                      href='https://www.facebook.com/informationstekniksverige'
                      target='_blank'
                      rel='noreferrer '
                      aria-label='facebook'
                    >
                      <img src='/images/fb-icon.svg'></img>
                    </a>
                    <a
                      href='https://www.linkedin.com/company/informationsteknik'
                      target='_blank'
                      rel='noreferrer '
                      aria-label='linkedin'
                    >
                      <img src='/images/linkedin-icon.svg'></img>
                    </a>
                  </div>
                </section>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='container max-width-lg'>
        <div className={cn(styles.colophon, 'border-top border-4 border-contrast-lower margin-top-lg')}>
          <div className='padding-y-xs'>
            <Image
              src='/certifications_red.png'
              alt='road'
              width={256}
              height={56.88}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
