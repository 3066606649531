import {FC, memo} from "react";
import cn from 'classnames';

// Styles
import styles from "./hamburger.module.scss";

interface Props {
  isOpen: boolean;
  ariaLabel?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const HamburgerButton: FC<Props> = ({
  isOpen,
  ariaLabel = "Toggle menu",
  onClick,
}) => {
  return (
    <button
      className={cn(styles.button, 'reset')}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <i className={cn(styles.icon, { [styles.open]: isOpen})} aria-hidden="true"></i>
    </button>
  )
}

export default memo(HamburgerButton);
