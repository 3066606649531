import React, { Children, FC, isValidElement } from 'react';
import cn from 'classnames';

import s from './horizontal-slider.module.scss';

interface Props {
  className?: string;
}

const HorizontalSlider: FC<Props> = ({ children, className }) => {
  return (
    <div className={cn(s.root, className)}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return {
            ...child,
            props: {
              ...child.props,
              className: cn(s.item, {
                [`${child.props.className}`]: child.props.className,
              }),
            },
          };
        }
        return child;
      })}
    </div>
  );
};

export default HorizontalSlider;
