import { User } from 'interfaces/Storm/User';
import { UpdateCustomerRequest } from './interface';

export enum ActionTypes {
  login = 'AUTH_LOGIN',
  logout = 'AUTH_LOGOUT',
  updateUser = 'AUTH_UPDATE_USER',
}

export interface LoginUser {
  type: ActionTypes.login;
  payload: { user: User };
}

export interface LogoutUser {
  type: ActionTypes.logout;
}

export interface UpdateUser {
  type: ActionTypes.updateUser;
  payload: { user: UpdateCustomerRequest };
}

export type AuthActions = LoginUser | LogoutUser | UpdateUser;

export const login = (user: User): LoginUser => ({
  type: ActionTypes.login,
  payload: { user },
});

export const logout = (): LogoutUser => ({
  type: ActionTypes.logout,
});

export const updateUser = (user: UpdateCustomerRequest): UpdateUser => ({
  type: ActionTypes.updateUser,
  payload: { user },
});
